/* stylelint-disable stylelint-plugins/no-hardcoded-colors */
/* stylelint-disable max-nesting-depth */

@import '../core/helpers';

@mixin spx-card($theme, $status) {
  .spx-card {
    mat-card-title {
      margin-bottom: 0 !important;
      padding: 0;

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .spx-icon svg {
        fill: #fff;
        stroke: #fff;
      }
    }

    mat-card-actions {
      margin: 0 !important;
      padding: 16px 16px 16px 20px !important;
    }

    .mat-mdc-card-header-text {
      margin: 0 !important;
      width: 100%;
    }
  }
}

/* stylelint-enable stylelint-plugins/no-hardcoded-colors */
/* stylelint-enable max-nesting-depth */
