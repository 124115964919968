@use '@angular/material' as mat;
@import 'scss/defaults/typography-config';

mat-form-field {
  width: 100%;
}

mat-radio-button {
  margin: 0 0 8px 4px;
}

mat-label {
  margin-bottom: 4px;
}

// Full width buttons
.spx-button--full-width {
  .spx-button {
    width: 100%;
  }
}

// Big Home Buttons
.home-buttons .spx-button {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  // font-size: 14px !important;
  @include mat.m2-typography-level($typography-config, 'h5');
  height: 160px !important;
  width: 100%;

  .mat-icon {
    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
    font-size: 45px !important;
    height: 45px !important;
    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
    line-height: 45px !important;
    margin: 0 0 8px !important;
    width: 45px !important;
  }
}

@media screen and (max-width: 760px) {
  .spx-dialog {
    height: 100%;
    max-width: 100% !important;
  }
}

.button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  padding: 16px 0 0 0;
}

.responsive-button {
  width: auto; /* Default width for desktop */
  display: inline-block; /* Ensure it only takes up as much space as needed */
}

@media (max-width: 768px) {
  .responsive-button {
    width: 100% !important; /* Full width for mobile */
    display: block;
  }
}
