mat-form-field .mat-icon.password-icon {
  color: var(--primary);
  opacity: 0.5;
  cursor: pointer;
  width: 19px;
  visibility: hidden;
}

mat-form-field:focus-within .mat-icon.password-icon,
mat-form-field:focus .mat-icon.password-icon,
mat-form-field:hover .mat-icon.password-icon {
  visibility: visible;
}
