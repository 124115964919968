@use '@angular/material' as mat;

$typography: (
  headline-1: mat.m2-define-typography-level(96px, 112px, 300, 'Roboto, sans-serif', -1.5px),
  headline-2: mat.m2-define-typography-level(60px, 72px, 400, 'Roboto, sans-serif', -0.5px),
  headline-3: mat.m2-define-typography-level(48px, 56px, 700, 'Roboto, sans-serif', 0),
  headline-4: mat.m2-define-typography-level(34px, 42px, 400, 'Roboto, sans-serif', 0),
  h1: mat.m2-define-typography-level(32px, 40px, 600, 'Roboto, sans-serif', 0),
  h2: mat.m2-define-typography-level(24px, 32px, 500, 'Roboto, sans-serif', 0),
  h3: mat.m2-define-typography-level(20px, 32px, 500, 'Roboto, sans-serif', 0.15px),
  h4: mat.m2-define-typography-level(18px, 24px, 500, 'Roboto, sans-serif', 0.15px),
  h5: mat.m2-define-typography-level(14px, 20px, 500, 'Roboto, sans-serif', 0.4px),
  s1: mat.m2-define-typography-level(18px, 26px, 400, 'Roboto, sans-serif', 0),
  s2: mat.m2-define-typography-level(14px, 20px, 600, 'Roboto, sans-serif', 0.15px),
  body1: mat.m2-define-typography-level(16px, 24px, 400, 'Roboto, sans-serif', 0),
  body1-medium: mat.m2-define-typography-level(16px, 24px, 500, 'Roboto, sans-serif', 0.15px),
  body2: mat.m2-define-typography-level(14px, 20px, 400, 'Roboto, sans-serif', 0),
  body2-medium: mat.m2-define-typography-level(14px, 20px, 500, 'Roboto, sans-serif', 0.15px),
  body3: mat.m2-define-typography-level(12px, 18px, 400, 'Roboto, sans-serif', 0),
  body3-medium: mat.m2-define-typography-level(12px, 18px, 500, 'Roboto, sans-serif', 0.15px),
  body4: mat.m2-define-typography-level(10px, 14px, 400, 'Roboto, sans-serif', 0),
  body4-medium: mat.m2-define-typography-level(10px, 14px, 500, 'Roboto, sans-serif', 0.25px),
  button: mat.m2-define-typography-level(12px, 20px, 500, 'Roboto, sans-serif', 0.4px),
  button-group: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto, sans-serif', 0.15px),
  label: mat.m2-define-typography-level(10px, 10px, 400, 'Roboto, sans-serif', 0.15px),
);

@mixin spx-typography($typography-config) {
  .spx-typography {
    h1 {
      @include mat.m2-typography-level($typography-config, 'h1');
    }

    h2 {
      @include mat.m2-typography-level($typography-config, 'h2');
    }

    h3 {
      @include mat.m2-typography-level($typography-config, 'h3');
    }

    h4 {
      @include mat.m2-typography-level($typography-config, 'h4');
    }

    h5 {
      @include mat.m2-typography-level($typography-config, 'h5');
    }

    p {
      @include mat.m2-typography-level($typography-config, 'body2');
    }

    @each $key, $_ in $typography {
      .spx-#{$key} {
        @include mat.m2-typography-level($typography-config, $key);
      }
    }
  }
}
