// default size of all icons. Icon sizes can be overwritten
$default-icon-size: 18px;

// Navigation icon size
$big-icon-size: 24px;

/* stylelint-disable-next-line selector-no-qualifying-type */
spx-icon[ng-reflect-file-path] mat-icon,
.material-icons:not(.mdc-fab),
.material-icons-outlined:not(.mdc-fab),
.material-symbols-rounded:not(.mdc-fab),
.material-symbols-sharp:not(.mdc-fab),
.material-symbols-outlined:not(.mdc-fab) {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-size: $default-icon-size;
  height: $default-icon-size;
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: $default-icon-size;
  width: $default-icon-size;
}

.mat-icon-button .mat-icon:not(.mdc-fab) {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: $default-icon-size;
}

.spx-icon-button button:not(.mdc-fab) {
  .spx-icon.mat-icon {
    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
    font-size: $default-icon-size;
    height: $default-icon-size;
    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
    line-height: $default-icon-size;
    width: $default-icon-size;
  }
}

.mdc-fab--mini {
  .spx-icon.mat-icon {
    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
    font-size: calc(#{$default-icon-size} * 0.8);
    height: calc(#{$default-icon-size} * 0.8);
    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
    line-height: calc(#{$default-icon-size} * 0.8);
    width: calc(#{$default-icon-size} * 0.8);
  }
}

.spx-icon-big {
  .spx-icon.mat-icon {
    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
    font-size: $big-icon-size;
    height: $big-icon-size;
    /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
    line-height: $big-icon-size;
    width: $big-icon-size;
  }
}
