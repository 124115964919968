/* You can add global styles to this file, and also import other style files */
@import './defaults/css-theme';
@import './core/helpers';

:root {
  @include define-colors('primary-10', #eee);
  @include define-colors('primary-50', #fbfbfb);
  @include define-colors('primary-100', #f6f6f6);
  @include define-colors('primary-200', #f1f1f1);
  @include define-colors('primary-300', #e5e5e5);
  @include define-colors('primary-400', #c3c3c3);
  @include define-colors('primary-500', #a5a5a5);
  @include define-colors('primary-600', #7b7b7b);
  @include define-colors('primary-700', #676767);
  @include define-colors('primary-800', #484848);
  @include define-colors('primary-900', #262626);
  @include define-colors('primary-A100', #f6f6f6);
  @include define-colors('primary-A200', #f1f1f1);
  @include define-colors('primary-A400', #c3c3c3);
  @include define-colors('primary-A700', #676767);

  @include define-colors('accent-50', #ede7f6);
  @include define-colors('accent-100', #d1c4e9);
  @include define-colors('accent-200', #b49ddb);
  @include define-colors('accent-300', #9675cd);
  @include define-colors('accent-400', #7f57c2);
  @include define-colors('accent-500', #683ab7);
  @include define-colors('accent-600', #5f35b1);
  @include define-colors('accent-700', #522da8);
  @include define-colors('accent-800', #4727a0);
  @include define-colors('accent-900', #321b92);
  @include define-colors('accent-A100', #d4c2f4);
  @include define-colors('accent-A200', #b899ee);
  @include define-colors('accent-A400', #834be3);
  @include define-colors('accent-A700', #4c15ce);

  @include define-colors('warn-50', #ffebee);
  @include define-colors('warn-100', #ffcdd2);
  @include define-colors('warn-200', #ef9a9a);
  @include define-colors('warn-300', #e57373);
  @include define-colors('warn-400', #ef5350);
  @include define-colors('warn-500', #f44336);
  @include define-colors('warn-600', #e53935);
  @include define-colors('warn-700', #d32f2f);
  @include define-colors('warn-800', #c62828);
  @include define-colors('warn-900', #b71c1c);
  @include define-colors('warn-A100', #ff8a80);
  @include define-colors('warn-A200', #ff5252);
  @include define-colors('warn-A400', #ff1744);
  @include define-colors('warn-A700', #d50000);

  @include define-colors('status-info', #0c4e99);
  @include define-colors('status-success', #528a04);
  @include define-colors('status-warn', #f29f05);
  @include define-colors('status-alert', #f26811);
  @include define-colors('status-error', #e62d1e);
  @include define-colors('status-absent', #454545);
  @include define-colors('primary-service-point', #eee);
  @include define-colors('default-page-background', #fbfbfb);

  @include define-colors('white', #fff);
  @include define-colors('black', #000);
  @include define-colors('black-a0', rgba(#000, 0));
  @include define-colors('black-a1', rgba(#000, 0.1));
  @include define-colors('black-a2', rgba(#000, 0.2));
  @include define-colors('black-a3', rgba(#000, 0.3));
  @include define-colors('black-a4', rgba(#000, 0.4));
  @include define-colors('black-a5', rgba(#000, 0.5));
  @include define-colors('black-a6', rgba(#000, 0.6));
  @include define-colors('black-a7', rgba(#000, 0.7));
  @include define-colors('black-a8', rgba(#000, 0.8));
  @include define-colors('black-a9', rgba(#000, 0.9));
  @include define-colors('black-a10', rgba(#000, 1));

  @include define-colors('note', #fffbf0);

  @include theme-defaults-vars('primary', 800, 300, 900);
  @include theme-defaults-vars('accent', 700, 300, 900);
  @include theme-defaults-vars('warn', 500, 100, 700);

  @include app-color('primary');
  @include menu-color('accent');
}
