@import '../core/helpers';

@mixin spx-icon-button($theme, $status) {
  .spx-icon-button {
    align-items: center;
    display: flex !important;
    justify-content: center;

    .spx-icon {
      align-items: center;
      display: inline-flex;
      justify-content: center;
    }

    &-primary {
      @include spx-icon-button-color(color($theme), contrast-color($theme));
    }

    &-accent {
      @include spx-icon-button-color(color($theme, 'accent'), contrast-color($theme, 'accent'));
    }

    &-warn {
      @include spx-icon-button-color(color($theme, 'warn'), contrast-color($theme, 'warn'));
    }

    @each $statusKey in $status {
      &-status-#{$statusKey} {
        @include spx-icon-button-color(color($theme, 'status', $statusKey), color($theme, 'status', #{$statusKey}-contrast));
      }
    }
  }
}

/**
* Mixin to set the icon button colors
*/
@mixin spx-icon-button-color($color, $textColor) {
  &.mdc-icon-button:not(:disabled) {
    mat-icon {
      color: $color;
    }

    mat-icon svg {
      fill: currentColor;
      stroke: currentColor;
    }
  }

  &.mdc-fab:not(:disabled) {
    background-color: $color;
    color: $textColor;

    svg {
      fill: currentColor;
      stroke: currentColor;
    }
  }
}
