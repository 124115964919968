/* stylelint-disable scss/no-global-function-names */
@use 'sass:map';
@use 'sass:math';
@use '@angular/material' as mat;

/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
@function add-vars-to-palette($palette, $name) {
  /* stylelint-disable function-parentheses-space-inside */
  $new-palette: map.merge(
    $palette,
    (
      'default': var(--#{$name}-default),
      'lighter': var(--#{$name}-lighter),
      'darker': var(--#{$name}-darker),
      'text': var(--#{$name}-text),
      'default-contrast': var(--#{$name}-default-contrast),
      'lighter-contrast': var(--#{$name}-lighter-contrast),
      'darker-contrast': var(--#{$name}-darker-contrast),
    )
  );
  /* stylelint-enable function-parentheses-space-inside */
  @return $new-palette;
}

/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
@function color($theme, $palette: 'primary', $hue: 'default') {
  // Get the color palette from the color-config
  $primary-palette: get-palette($theme, $palette);

  @return mat.m2-get-color-from-palette($primary-palette, $hue);
}

/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
@function text-color($theme, $palette: 'primary') {
  // Get the color palette from the color-config
  $primary-palette: get-palette($theme, $palette);

  @return mat.m2-get-color-from-palette($primary-palette, 'text');
}

/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
@function contrast-color($theme, $palette: 'primary', $hue: 'default') {
  // Get the color palette from the color-config
  $primary-palette: get-palette($theme, $palette);

  // Adding contrast to get contrast color from material theme
  $hue: $hue + '-contrast';

  @return mat.m2-get-color-from-palette($primary-palette, $hue);
}

/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
@function get-palette($theme, $palette: 'primary') {
  // Get the color config from the theme
  $color-config: mat.m2-get-color-config($theme);

  // Get the color palette from the color-config
  @return map.get($color-config, $palette);
}

/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
@function add-color-config($theme, $color-config) {
  $theme: map.merge($theme, $color-config);
  @return $theme;
}

/**
* Helper function to get values out of a scss map
*/
@function get-map-value($map, $key) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  }
  @error 'Unknown `#{$key}` in `#{$map}`.';
  @return null;
}

/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
@mixin create-vars-from-theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @each $key, $value in $color-config {
    @if (type-of($value) == 'map' and $value) {
      @include create-vars-from-color-config($value, $key);
    }
  }
}

/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
@mixin create-vars-from-color-config($config, $config-name) {
  @each $key, $value in $config {
    @if (type-of($value) != 'map' and $value) {
      --#{$config-name}-#{$key}: #{$value};
    }
  }
}

/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
@mixin define-colors($name, $background) {
  $contrast-color: #fff;
  // https://www.w3.org/TR/AERT/#color-contrast
  $color-brightness: round(math.div((red($background) * 299) + (green($background) * 587) + (blue($background) * 114), 1000));

  $light-color: round(math.div((red(#fff) * 299) + (green(#fff) * 587) + (blue(#fff) * 114), 1000));

  @if abs($color-brightness) < math.div($light-color, 2) {
    $contrast-color: #fff;
  } @else {
    $contrast-color: #000;
  }
  /* stylelint-disable order/order */
  --#{$name}: #{$background};
  --#{$name}-contrast: #{$contrast-color};
  /* stylelint-enable order/order */
}

/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
@mixin theme-defaults-vars($palette, $hue, $lighter, $darker) {
  --#{$palette}: var(--#{$palette}-#{$hue});
  --#{$palette}-text: var(--#{$palette}-#{$hue});
  --#{$palette}-contrast: var(--#{$palette}-#{$hue}-contrast);

  --#{$palette}-default: var(--#{$palette}-#{$hue});
  --#{$palette}-default-contrast: var(--#{$palette}-#{$hue}-contrast);

  --#{$palette}-lighter: var(--#{$palette}-#{$lighter});
  --#{$palette}-lighter-contrast: var(--#{$palette}-#{$lighter}-contrast);

  --#{$palette}-darker: var(--#{$palette}-#{$darker});
  --#{$palette}-darker-contrast: var(--#{$palette}-#{$darker}-contrast);
}

/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
@mixin app-color($palette) {
  --app-color: var(--#{$palette});
  --app-color-contrast: var(--#{$palette}-contrast);
}

@mixin menu-color($palette) {
  --app-color-menu: var(--#{$palette});
  --app-color-menu-contrast: var(--#{$palette}-contrast);
}
/* stylelint-enable scss/no-global-function-names */
