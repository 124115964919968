@use '@angular/material' as mat;
/**
* Range Selector THEMING
*/
@mixin spx-range-selector($theme) {
  .range-picker-select-unit {
    min-width: 100px;
  }

  spx-period-option-list {
    @include mat.form-field-density(-5);

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}
