/* You can add global styles to this file, and also import other style files */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable function-parentheses-space-inside */
/* stylelint-enable function-parentheses-space-inside */
mat-form-field label {
  padding-right: 4px;
}

mat-form-field.ng-dirty label {
  font-style: italic;
}

a {
  text-decoration: none;
}

mat-card {
  padding: 16px;
}

.mat-mdc-form-field-infix {
  align-items: center;
  display: flex;
}

mat-divider {
  margin-left: 0 !important;
}

.mat-mdc-card-header-text {
  margin: 0 16px;
}

.mat-mdc-snack-bar-label.mdc-snackbar__label {
  margin: auto 8px;
}

spx-range-selector-input {
  --mat-form-field-container-height: 44px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 10px;
  --mat-form-field-filled-with-label-container-padding-top: 10px;
  --mat-form-field-filled-with-label-container-padding-bottom: 10px;
}
spx-range-selector-input .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

mat-button-toggle {
  align-items: center;
  display: flex;
  height: 30px;
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: 30px;
}

mat-button-toggle .mat-button-toggle-label-content {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}

.mat-mdc-menu-item-text,
.mat-mdc-form-field-icon-suffix,
.mat-mdc-chip-action-label,
mat-option > span,
.mat-mdc-list-item-unscoped-content {
  display: flex;
}

mat-option > span {
  align-items: center;
}

.mat-mdc-option .mat-icon.spx-icon {
  margin-right: 8px;
}

.mat-mdc-dialog-container .mdc-dialog__content.mat-mdc-dialog-content {
  color: unset;
}

mat-card-subtitle.mat-mdc-card-subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}

.mat-mdc-autocomplete-panel {
  padding-top: 0;
}

.mat-mdc-simple-snack-bar {
  padding: 1rem;
}

.mat-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}

.mat-mdc-menu-item-text {
  align-items: center;
  flex-direction: row;
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 16px;
}

[color=primary] .mdc-tab:hover {
  background-color: var(--primary-50) !important;
}
[color=primary] .mdc-tab-indicator--active {
  color: var(--primary) !important;
  opacity: 1;
}
[color=primary] mat-button-toggle:not(.mat-button-toggle-disabled) {
  color: var(--primary) !important;
}
[color=primary] .mat-button-toggle-checked {
  background-color: var(--primary-300) !important;
  color: var(--primary-300-contrast) !important;
}
[color=primary] .mdc-checkbox__checkmark {
  color: var(--primary-contrast) !important;
}
[color=primary] .mdc-checkbox__mixedmark {
  border-color: var(--primary-contrast) !important;
}

[color=accent] .mdc-tab:hover {
  background-color: var(--accent-50) !important;
}
[color=accent] .mdc-tab-indicator--active {
  color: var(--accent) !important;
  opacity: 1;
}
[color=accent] mat-button-toggle:not(.mat-button-toggle-disabled) {
  color: var(--accent) !important;
}
[color=accent] .mat-button-toggle-checked {
  background-color: var(--accent-300) !important;
  color: var(--accent-300-contrast) !important;
}
[color=accent] .mdc-checkbox__checkmark {
  color: var(--accent-contrast) !important;
}
[color=accent] .mdc-checkbox__mixedmark {
  border-color: var(--accent-contrast) !important;
}
[color=accent].mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background, [color=accent][ng-reflect-indeterminate=true] .mdc-checkbox__background {
  background-color: var(--accent) !important;
}
[color=accent].mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background, [color=accent][ng-reflect-indeterminate=true] .mdc-checkbox__background {
  border-color: var(--accent) !important;
}

[color=warn] .mdc-tab:hover {
  background-color: var(--warn-50) !important;
}
[color=warn] .mdc-tab-indicator--active {
  color: var(--warn) !important;
  opacity: 1;
}
[color=warn] mat-button-toggle:not(.mat-button-toggle-disabled) {
  color: var(--warn) !important;
}
[color=warn] .mat-button-toggle-checked {
  background-color: var(--warn-300) !important;
  color: var(--warn-300-contrast) !important;
}
[color=warn] .mdc-checkbox__checkmark {
  color: var(--warn-contrast) !important;
}
[color=warn] .mdc-checkbox__mixedmark {
  border-color: var(--warn-contrast) !important;
}

/* stylelint-enable selector-class-pattern */
.mat-calendar-body-cell-content {
  border: 0 !important;
}

.mat-datepicker-content {
  /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.1411764706), 0 1px 10px rgba(0, 0, 0, 0.1215686275);
  background-color: var(--primary-contrast);
  color: var(--primary);
}

.mat-primary .mat-calendar-body-selected {
  background-color: var(--primary);
  color: var(--primary-contrast);
}
.mat-primary .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):hover {
  background-color: var(--primary-300);
  color: var(--primary-300-contrast);
}

.mat-accent .mat-calendar-body-selected {
  background-color: var(--accent);
  color: var(--accent-contrast);
}
.mat-accent .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):hover {
  background-color: var(--accent-300);
  color: var(--accent-300-contrast);
}

.mat-warn .mat-calendar-body-selected {
  background-color: var(--warn);
  color: var(--warn-contrast);
}
.mat-warn .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):hover {
  background-color: var(--warn-300);
  color: var(--warn-300-contrast);
}

/* Material uses color.adjust to add opacity to progress-track-color.
   Thus we use css variables in theming and sass functions are not able to use them
   we have to set them here. Otherwise fore- and background would be the same
   @see https://github.com/angular/components/blob/422e85d1524f8eeff2425e403702fbfc23a2cc2e/src/material/core/tokens/m2/mdc/_linear-progress.scss#L35
*/
mat-progress-bar.mat-mdc-progress-bar.mat-primary {
  --mdc-linear-progress-track-color: var(--primary-lighter);
}
mat-progress-bar.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-track-color: var(--warn-lighter);
}
mat-progress-bar.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-track-color: var(--accent-lighter);
}

/**
* Material 2 legacy is missing this class since Update to angular material 19.
*/
.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
spx-icon[ng-reflect-file-path] mat-icon,
.material-icons:not(.mdc-fab),
.material-icons-outlined:not(.mdc-fab),
.material-symbols-rounded:not(.mdc-fab),
.material-symbols-sharp:not(.mdc-fab),
.material-symbols-outlined:not(.mdc-fab) {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-size: 18px;
  height: 18px;
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: 18px;
  width: 18px;
}

.mat-icon-button .mat-icon:not(.mdc-fab) {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: 18px;
}

.spx-icon-button button:not(.mdc-fab) .spx-icon.mat-icon {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-size: 18px;
  height: 18px;
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: 18px;
  width: 18px;
}

.mdc-fab--mini .spx-icon.mat-icon {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-size: calc(18px * 0.8);
  height: calc(18px * 0.8);
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: calc(18px * 0.8);
  width: calc(18px * 0.8);
}

.spx-icon-big .spx-icon.mat-icon {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-size: 24px;
  height: 24px;
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: 24px;
  width: 24px;
}

/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable stylelint-plugins/no-hardcoded-colors */
/* stylelint-disable stylelint-plugins/no-font-properties */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/**
* BUTTON THEMING
*/
/**
* Mixin to set the scss properties for the material buttons
*/
/* stylelint-enable stylelint-plugins/no-hardcoded-colors */
/* stylelint-enable stylelint-plugins/no-font-properties */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/**
* Mixin to set the icon button colors
*/
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable stylelint-plugins/no-hardcoded-colors */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-enable stylelint-plugins/no-hardcoded-colors */
/* stylelint-enable max-nesting-depth */
/* stylelint-disable stylelint-plugins/no-font-properties */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-enable stylelint-plugins/no-font-properties */
mat-form-field .mat-icon.password-icon {
  color: var(--primary);
  opacity: 0.5;
  cursor: pointer;
  width: 19px;
  visibility: hidden;
}

mat-form-field:focus-within .mat-icon.password-icon,
mat-form-field:focus .mat-icon.password-icon,
mat-form-field:hover .mat-icon.password-icon {
  visibility: visible;
}

/**
* Range Selector THEMING
*/
/* stylelint-disable selector-class-pattern */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-enable selector-class-pattern */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable function-parentheses-space-inside */
/* stylelint-enable function-parentheses-space-inside */
/**
* MAIN SEEPEX THEMING
*/
/* stylelint-disable function-parentheses-space-inside */
/* stylelint-enable function-parentheses-space-inside */
/* Theme */
html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: var(--primary-default);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: var(--accent-default);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: var(--warn-default);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--accent-default);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
html {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--accent-default);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary-default);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary-default);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--accent-default);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--accent-default);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--warn-default);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--warn-default);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
}
html {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 24px;
  --mat-card-title-text-tracking: 0;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 32px;
  --mat-card-subtitle-text-size: 20px;
  --mat-card-subtitle-text-tracking: 0.15px;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primary-default);
  --mdc-linear-progress-track-color: var(--primary-default);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(--accent-default);
  --mdc-linear-progress-track-color: var(--accent-default);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: var(--warn-default);
  --mdc-linear-progress-track-color: var(--warn-default);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 10px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.15px;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
html {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
html {
  --mdc-filled-text-field-caret-color: var(--primary-default);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary-default);
  --mdc-filled-text-field-focus-label-text-color: var(--primary-default);
  --mdc-filled-text-field-container-color: rgb(244.8, 244.8, 244.8);
  --mdc-filled-text-field-disabled-container-color: rgb(249.9, 249.9, 249.9);
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: var(--warn-default);
  --mdc-filled-text-field-error-focus-label-text-color: var(--warn-default);
  --mdc-filled-text-field-error-label-text-color: var(--warn-default);
  --mdc-filled-text-field-error-caret-color: var(--warn-default);
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: var(--warn-default);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--warn-default);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--warn-default);
}
html {
  --mdc-outlined-text-field-caret-color: var(--primary-default);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-default);
  --mdc-outlined-text-field-focus-label-text-color: var(--primary-default);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: var(--warn-default);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--warn-default);
  --mdc-outlined-text-field-error-label-text-color: var(--warn-default);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--warn-default);
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: var(--warn-default);
  --mdc-outlined-text-field-error-hover-outline-color: var(--warn-default);
  --mdc-outlined-text-field-error-outline-color: var(--warn-default);
}
html {
  --mat-form-field-focus-select-arrow-color: var(--primary-default);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: var(--warn-default);
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: var(--accent-default);
  --mdc-filled-text-field-focus-active-indicator-color: var(--accent-default);
  --mdc-filled-text-field-focus-label-text-color: var(--accent-default);
}
.mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: var(--accent-default);
  --mdc-outlined-text-field-focus-outline-color: var(--accent-default);
  --mdc-outlined-text-field-focus-label-text-color: var(--accent-default);
}
.mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: var(--accent-default);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: var(--warn-default);
  --mdc-filled-text-field-focus-active-indicator-color: var(--warn-default);
  --mdc-filled-text-field-focus-label-text-color: var(--warn-default);
}
.mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: var(--warn-default);
  --mdc-outlined-text-field-focus-outline-color: var(--warn-default);
  --mdc-outlined-text-field-focus-label-text-color: var(--warn-default);
}
.mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: var(--warn-default);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0;
  --mdc-filled-text-field-label-text-weight: 400;
}
html {
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0;
  --mdc-outlined-text-field-label-text-weight: 400;
}
html {
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 10px;
  --mat-form-field-subscript-text-size: 10px;
  --mat-form-field-subscript-text-tracking: 0.15px;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--primary-default);
  --mat-select-invalid-arrow-color: var(--warn-default);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--accent-default);
  --mat-select-invalid-arrow-color: var(--warn-default);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--warn-default);
  --mat-select-invalid-arrow-color: var(--warn-default);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
}
html {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 24px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0;
}
.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: var(--primary-default-contrast);
  --mdc-chip-elevated-container-color: var(--primary-default);
  --mdc-chip-elevated-selected-container-color: var(--primary-default);
  --mdc-chip-elevated-disabled-container-color: var(--primary-default);
  --mdc-chip-flat-disabled-selected-container-color: var(--primary-default);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--primary-default-contrast);
  --mdc-chip-selected-label-text-color: var(--primary-default-contrast);
  --mdc-chip-with-icon-icon-color: var(--primary-default-contrast);
  --mdc-chip-with-icon-disabled-icon-color: var(--primary-default-contrast);
  --mdc-chip-with-icon-selected-icon-color: var(--primary-default-contrast);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--primary-default-contrast);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--primary-default-contrast);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: var(--primary-default-contrast);
  --mat-chip-selected-trailing-icon-color: var(--primary-default-contrast);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: var(--accent-default-contrast);
  --mdc-chip-elevated-container-color: var(--accent-default);
  --mdc-chip-elevated-selected-container-color: var(--accent-default);
  --mdc-chip-elevated-disabled-container-color: var(--accent-default);
  --mdc-chip-flat-disabled-selected-container-color: var(--accent-default);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--accent-default-contrast);
  --mdc-chip-selected-label-text-color: var(--accent-default-contrast);
  --mdc-chip-with-icon-icon-color: var(--accent-default-contrast);
  --mdc-chip-with-icon-disabled-icon-color: var(--accent-default-contrast);
  --mdc-chip-with-icon-selected-icon-color: var(--accent-default-contrast);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--accent-default-contrast);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--accent-default-contrast);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: var(--accent-default-contrast);
  --mat-chip-selected-trailing-icon-color: var(--accent-default-contrast);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: var(--warn-default-contrast);
  --mdc-chip-elevated-container-color: var(--warn-default);
  --mdc-chip-elevated-selected-container-color: var(--warn-default);
  --mdc-chip-elevated-disabled-container-color: var(--warn-default);
  --mdc-chip-flat-disabled-selected-container-color: var(--warn-default);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--warn-default-contrast);
  --mdc-chip-selected-label-text-color: var(--warn-default-contrast);
  --mdc-chip-with-icon-icon-color: var(--warn-default-contrast);
  --mdc-chip-with-icon-disabled-icon-color: var(--warn-default-contrast);
  --mdc-chip-with-icon-selected-icon-color: var(--warn-default-contrast);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--warn-default-contrast);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--warn-default-contrast);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: var(--warn-default-contrast);
  --mat-chip-selected-trailing-icon-color: var(--warn-default-contrast);
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0;
  --mdc-chip-label-text-weight: 400;
}
html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-600);
  --mdc-switch-selected-handle-color: var(--primary-600);
  --mdc-switch-selected-hover-state-layer-color: var(--primary-600);
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-600);
  --mdc-switch-selected-focus-handle-color: var(--primary-900);
  --mdc-switch-selected-hover-handle-color: var(--primary-900);
  --mdc-switch-selected-pressed-handle-color: var(--primary-900);
  --mdc-switch-selected-focus-track-color: var(--primary-300);
  --mdc-switch-selected-hover-track-color: var(--primary-300);
  --mdc-switch-selected-pressed-track-color: var(--primary-300);
  --mdc-switch-selected-track-color: var(--primary-300);
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--accent-600);
  --mdc-switch-selected-handle-color: var(--accent-600);
  --mdc-switch-selected-hover-state-layer-color: var(--accent-600);
  --mdc-switch-selected-pressed-state-layer-color: var(--accent-600);
  --mdc-switch-selected-focus-handle-color: var(--accent-900);
  --mdc-switch-selected-hover-handle-color: var(--accent-900);
  --mdc-switch-selected-pressed-handle-color: var(--accent-900);
  --mdc-switch-selected-focus-track-color: var(--accent-300);
  --mdc-switch-selected-hover-track-color: var(--accent-300);
  --mdc-switch-selected-pressed-track-color: var(--accent-300);
  --mdc-switch-selected-track-color: var(--accent-300);
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: var(--warn-600);
  --mdc-switch-selected-handle-color: var(--warn-600);
  --mdc-switch-selected-hover-state-layer-color: var(--warn-600);
  --mdc-switch-selected-pressed-state-layer-color: var(--warn-600);
  --mdc-switch-selected-focus-handle-color: var(--warn-900);
  --mdc-switch-selected-hover-handle-color: var(--warn-900);
  --mdc-switch-selected-pressed-handle-color: var(--warn-900);
  --mdc-switch-selected-focus-track-color: var(--warn-300);
  --mdc-switch-selected-hover-track-color: var(--warn-300);
  --mdc-switch-selected-pressed-track-color: var(--warn-300);
  --mdc-switch-selected-track-color: var(--warn-300);
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary-default);
  --mdc-radio-selected-hover-icon-color: var(--primary-default);
  --mdc-radio-selected-icon-color: var(--primary-default);
  --mdc-radio-selected-pressed-icon-color: var(--primary-default);
}
.mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: var(--primary-default);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--accent-default);
  --mdc-radio-selected-hover-icon-color: var(--accent-default);
  --mdc-radio-selected-icon-color: var(--accent-default);
  --mdc-radio-selected-pressed-icon-color: var(--accent-default);
}
.mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: var(--accent-default);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--warn-default);
  --mdc-radio-selected-hover-icon-color: var(--warn-default);
  --mdc-radio-selected-icon-color: var(--warn-default);
  --mdc-radio-selected-pressed-icon-color: var(--warn-default);
}
.mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: var(--warn-default);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
}
html {
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: var(--primary-default);
  --mdc-slider-focus-handle-color: var(--primary-default);
  --mdc-slider-hover-handle-color: var(--primary-default);
  --mdc-slider-active-track-color: var(--primary-default);
  --mdc-slider-inactive-track-color: var(--primary-default);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary-default);
  --mdc-slider-with-tick-marks-active-container-color: var(--primary-default-contrast);
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
}
html {
  --mat-slider-ripple-color: var(--primary-default);
  --mat-slider-hover-state-layer-color: rgba(var(--primary-default), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--primary-default), 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: var(--accent-default);
  --mdc-slider-focus-handle-color: var(--accent-default);
  --mdc-slider-hover-handle-color: var(--accent-default);
  --mdc-slider-active-track-color: var(--accent-default);
  --mdc-slider-inactive-track-color: var(--accent-default);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--accent-default);
  --mdc-slider-with-tick-marks-active-container-color: var(--accent-default-contrast);
}
html .mat-accent {
  --mat-slider-ripple-color: var(--accent-default);
  --mat-slider-hover-state-layer-color: rgba(var(--accent-default), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--accent-default), 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: var(--warn-default);
  --mdc-slider-focus-handle-color: var(--warn-default);
  --mdc-slider-hover-handle-color: var(--warn-default);
  --mdc-slider-active-track-color: var(--warn-default);
  --mdc-slider-inactive-track-color: var(--warn-default);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--warn-default);
  --mdc-slider-with-tick-marks-active-container-color: var(--warn-default-contrast);
}
html .mat-warn {
  --mat-slider-ripple-color: var(--warn-default);
  --mat-slider-hover-state-layer-color: rgba(var(--warn-default), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--warn-default), 0.2);
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 20px;
  --mdc-slider-label-label-text-line-height: 32px;
  --mdc-slider-label-label-text-tracking: 0.15px;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
html {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary-default);
  --mdc-radio-selected-hover-icon-color: var(--primary-default);
  --mdc-radio-selected-icon-color: var(--primary-default);
  --mdc-radio-selected-pressed-icon-color: var(--primary-default);
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--accent-default);
  --mdc-radio-selected-hover-icon-color: var(--accent-default);
  --mdc-radio-selected-icon-color: var(--accent-default);
  --mdc-radio-selected-pressed-icon-color: var(--accent-default);
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--warn-default);
  --mdc-radio-selected-hover-icon-color: var(--warn-default);
  --mdc-radio-selected-icon-color: var(--warn-default);
  --mdc-radio-selected-pressed-icon-color: var(--warn-default);
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: var(--primary-default-contrast);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-default);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-default);
  --mdc-checkbox-selected-icon-color: var(--primary-default);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-default);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-default);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-default);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-default);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: var(--accent-default-contrast);
  --mdc-checkbox-selected-focus-icon-color: var(--accent-default);
  --mdc-checkbox-selected-hover-icon-color: var(--accent-default);
  --mdc-checkbox-selected-icon-color: var(--accent-default);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent-default);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--accent-default);
  --mdc-checkbox-selected-hover-state-layer-color: var(--accent-default);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--accent-default);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: var(--warn-default-contrast);
  --mdc-checkbox-selected-focus-icon-color: var(--warn-default);
  --mdc-checkbox-selected-hover-icon-color: var(--warn-default);
  --mdc-checkbox-selected-icon-color: var(--warn-default);
  --mdc-checkbox-selected-pressed-icon-color: var(--warn-default);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--warn-default);
  --mdc-checkbox-selected-hover-state-layer-color: var(--warn-default);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--warn-default);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: var(--primary-default);
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
html {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 10px;
  --mdc-list-list-item-trailing-supporting-text-size: 10px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.15px;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}
.mdc-list-group__subheader {
  font: 500 18px / 24px Roboto, sans-serif;
  letter-spacing: 0.15px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 10px;
  --mat-paginator-container-text-size: 10px;
  --mat-paginator-container-text-tracking: 0.15px;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 10px;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
html {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--primary-default);
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--primary-default);
  --mat-tab-header-active-ripple-color: var(--primary-default);
  --mat-tab-header-inactive-ripple-color: var(--primary-default);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--primary-default);
  --mat-tab-header-active-hover-label-text-color: var(--primary-default);
  --mat-tab-header-active-focus-indicator-color: var(--primary-default);
  --mat-tab-header-active-hover-indicator-color: var(--primary-default);
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: var(--accent-default);
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--accent-default);
  --mat-tab-header-active-ripple-color: var(--accent-default);
  --mat-tab-header-inactive-ripple-color: var(--accent-default);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--accent-default);
  --mat-tab-header-active-hover-label-text-color: var(--accent-default);
  --mat-tab-header-active-focus-indicator-color: var(--accent-default);
  --mat-tab-header-active-hover-indicator-color: var(--accent-default);
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: var(--warn-default);
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--warn-default);
  --mat-tab-header-active-ripple-color: var(--warn-default);
  --mat-tab-header-inactive-ripple-color: var(--warn-default);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--warn-default);
  --mat-tab-header-active-hover-label-text-color: var(--warn-default);
  --mat-tab-header-active-focus-indicator-color: var(--warn-default);
  --mat-tab-header-active-hover-indicator-color: var(--warn-default);
}
.mat-mdc-tab-group.mat-background-primary,
.mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--primary-default);
  --mat-tab-header-with-background-foreground-color: var(--primary-default-contrast);
}
.mat-mdc-tab-group.mat-background-accent,
.mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: var(--accent-default);
  --mat-tab-header-with-background-foreground-color: var(--accent-default-contrast);
}
.mat-mdc-tab-group.mat-background-warn,
.mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: var(--warn-default);
  --mat-tab-header-with-background-foreground-color: var(--warn-default-contrast);
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 12px;
  --mat-tab-header-label-text-tracking: 0.4px;
  --mat-tab-header-label-text-line-height: 20px;
  --mat-tab-header-label-text-weight: 500;
}
html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: var(--accent-default-contrast);
  --mdc-checkbox-selected-focus-icon-color: var(--accent-default);
  --mdc-checkbox-selected-hover-icon-color: var(--accent-default);
  --mdc-checkbox-selected-icon-color: var(--accent-default);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent-default);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--accent-default);
  --mdc-checkbox-selected-hover-state-layer-color: var(--accent-default);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--accent-default);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
html {
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: var(--primary-default-contrast);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-default);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-default);
  --mdc-checkbox-selected-icon-color: var(--primary-default);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-default);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-default);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-default);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-default);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: var(--warn-default-contrast);
  --mdc-checkbox-selected-focus-icon-color: var(--warn-default);
  --mdc-checkbox-selected-hover-icon-color: var(--warn-default);
  --mdc-checkbox-selected-icon-color: var(--warn-default);
  --mdc-checkbox-selected-pressed-icon-color: var(--warn-default);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--warn-default);
  --mdc-checkbox-selected-hover-state-layer-color: var(--warn-default);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--warn-default);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}
html {
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
html {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
html {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
html {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
html {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
html {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
html {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
html {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--primary-default);
}
.mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: var(--primary-default);
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: var(--accent-default);
}
.mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: var(--accent-default);
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: var(--warn-default);
}
.mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: var(--warn-default);
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--primary-default);
  --mdc-filled-button-label-text-color: var(--primary-default-contrast);
}
.mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: var(--accent-default);
  --mdc-filled-button-label-text-color: var(--accent-default-contrast);
}
.mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: var(--warn-default);
  --mdc-filled-button-label-text-color: var(--warn-default-contrast);
}
.mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--primary-default);
  --mdc-protected-button-label-text-color: var(--primary-default-contrast);
}
.mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--accent-default);
  --mdc-protected-button-label-text-color: var(--accent-default-contrast);
}
.mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: var(--warn-default);
  --mdc-protected-button-label-text-color: var(--warn-default-contrast);
}
.mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--primary-default);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: var(--primary-default);
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: var(--accent-default);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: var(--accent-default);
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: var(--warn-default);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: var(--warn-default);
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
}
html {
  --mdc-filled-button-container-height: 36px;
}
html {
  --mdc-protected-button-container-height: 36px;
}
html {
  --mdc-outlined-button-container-height: 36px;
}
html {
  --mat-text-button-touch-target-display: block;
}
html {
  --mat-filled-button-touch-target-display: block;
}
html {
  --mat-protected-button-touch-target-display: block;
}
html {
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 12px;
  --mdc-text-button-label-text-tracking: 0.4px;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
}
html {
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 12px;
  --mdc-filled-button-label-text-tracking: 0.4px;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
}
html {
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 12px;
  --mdc-protected-button-label-text-tracking: 0.4px;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}
html {
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 12px;
  --mdc-outlined-button-label-text-tracking: 0.4px;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}
html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--primary-default);
}
html .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: var(--primary-default);
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: var(--accent-default);
}
html .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: var(--accent-default);
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: var(--warn-default);
}
html .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: var(--warn-default);
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
}
html {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html {
  --mdc-fab-small-container-color: white;
}
html {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: var(--primary-default);
}
html .mat-mdc-fab.mat-primary {
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: var(--accent-default);
}
html .mat-mdc-fab.mat-accent {
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: var(--warn-default);
}
html .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: var(--primary-default);
}
html .mat-mdc-mini-fab.mat-primary {
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: var(--accent-default);
}
html .mat-mdc-mini-fab.mat-accent {
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: var(--warn-default);
}
html .mat-mdc-mini-fab.mat-warn {
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
}
html {
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 12px;
  --mdc-extended-fab-label-text-tracking: 0.4px;
  --mdc-extended-fab-label-text-weight: 500;
}
html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
html {
  --mat-snack-bar-button-color: var(--accent-text);
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 32px;
  --mat-table-header-headline-size: 20px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.15px;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: var(--primary-default);
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--accent-default);
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--warn-default);
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: var(--primary-default);
  --mat-badge-text-color: var(--primary-default-contrast);
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: var(--accent-default);
  --mat-badge-text-color: var(--accent-default-contrast);
}

.mat-badge-warn {
  --mat-badge-background-color: var(--warn-default);
  --mat-badge-text-color: var(--warn-default-contrast);
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
html {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
}
html {
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: rgb(224.4, 224.4, 224.4);
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0;
  --mat-legacy-button-toggle-label-text-weight: 400;
}
html {
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--primary-default-contrast);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-default);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--primary-default-contrast);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary-default);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary-default);
  --mat-datepicker-toggle-active-state-icon-color: var(--primary-text);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--primary-default), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--accent-default-contrast);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--accent-default);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--accent-default-contrast);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--accent-default);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--accent-default);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--accent-default), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--warn-default-contrast);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--warn-default);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--warn-default-contrast);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--warn-default);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--warn-default);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--warn-default), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: var(--accent-text);
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: var(--warn-text);
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 12px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 12px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 20px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 10px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 10px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: var(--primary-text);
}
.mat-icon.mat-accent {
  --mat-icon-color: var(--accent-text);
}
.mat-icon.mat-warn {
  --mat-icon-color: var(--warn-text);
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: var(--primary-default-contrast);
  --mat-stepper-header-selected-state-icon-background-color: var(--primary-default);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--primary-default-contrast);
  --mat-stepper-header-done-state-icon-background-color: var(--primary-default);
  --mat-stepper-header-done-state-icon-foreground-color: var(--primary-default-contrast);
  --mat-stepper-header-edit-state-icon-background-color: var(--primary-default);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--primary-default-contrast);
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: var(--warn-text);
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: var(--warn-text);
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: var(--accent-default-contrast);
  --mat-stepper-header-selected-state-icon-background-color: var(--accent-default);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--accent-default-contrast);
  --mat-stepper-header-done-state-icon-background-color: var(--accent-default);
  --mat-stepper-header-done-state-icon-foreground-color: var(--accent-default-contrast);
  --mat-stepper-header-edit-state-icon-background-color: var(--accent-default);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--accent-default-contrast);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: var(--warn-default-contrast);
  --mat-stepper-header-selected-state-icon-background-color: var(--warn-default);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--warn-default-contrast);
  --mat-stepper-header-done-state-icon-background-color: var(--warn-default);
  --mat-stepper-header-done-state-icon-foreground-color: var(--warn-default-contrast);
  --mat-stepper-header-edit-state-icon-background-color: var(--warn-default);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--warn-default-contrast);
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: rgb(117.3, 117.3, 117.3);
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: var(--primary-default);
  --mat-toolbar-container-text-color: var(--primary-default-contrast);
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: var(--accent-default);
  --mat-toolbar-container-text-color: var(--accent-default-contrast);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: var(--warn-default);
  --mat-toolbar-container-text-color: var(--warn-default-contrast);
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 24px;
  --mat-toolbar-title-text-tracking: 0;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-timepicker-container-background-color: white;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: 0;
  --mdc-filled-text-field-label-text-weight: 400;
}
html {
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: 0;
  --mdc-outlined-text-field-label-text-weight: 400;
}
html {
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 14px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: 0;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 10px;
  --mat-form-field-subscript-text-size: 10px;
  --mat-form-field-subscript-text-tracking: 0.15px;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 14px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: 0;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 14px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: 0;
  --mat-option-label-text-weight: 400;
}

.spx-typography h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
}
.spx-typography p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-headline-1 {
  font-size: 96px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, sans-serif;
  letter-spacing: -1.5px;
}
.spx-typography .spx-headline-2 {
  font-size: 60px;
  font-weight: 400;
  line-height: 72px;
  font-family: Roboto, sans-serif;
  letter-spacing: -0.5px;
}
.spx-typography .spx-headline-3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-headline-4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 42px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography .spx-h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography .spx-h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
}
.spx-typography .spx-s1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-s2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography .spx-body1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-body1-medium {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography .spx-body2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-body2-medium {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography .spx-body3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-body3-medium {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography .spx-body4 {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
}
.spx-typography .spx-body4-medium {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.25px;
}
.spx-typography .spx-button {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
}
.spx-typography .spx-button-group {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
.spx-typography .spx-label {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}

.spx-button {
  background-color: #fff;
  /* stylelint-disable selector-class-pattern */
  /* stylelint-enable selector-class-pattern */
}
.spx-button .mdc-button,
.spx-button .mdc-button__label {
  align-items: center;
  display: flex;
  justify-content: center;
}
.spx-button .mdc-button__label {
  line-height: 1;
}
.spx-button mat-icon,
.spx-button .spx-icon {
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  width: 16px;
}
.spx-button-primary.mdc-button:not(:disabled), .spx-button-primary.mdc-button--outlined:not(:disabled) {
  color: var(--primary-default);
}
.spx-button-primary.mdc-button:not(:disabled) svg, .spx-button-primary.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-primary.mdc-button--raised:not(:disabled), .spx-button-primary.mdc-button--unelevated:not(:disabled) {
  background-color: var(--primary-default);
  color: var(--primary-default-contrast);
}
.spx-button-primary.mdc-button--raised:not(:disabled) svg, .spx-button-primary.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-primary.mdc-button--outlined:not(:disabled) {
  border-color: var(--primary-lighter);
}
.spx-button-accent.mdc-button:not(:disabled), .spx-button-accent.mdc-button--outlined:not(:disabled) {
  color: var(--accent-default);
}
.spx-button-accent.mdc-button:not(:disabled) svg, .spx-button-accent.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-accent.mdc-button--raised:not(:disabled), .spx-button-accent.mdc-button--unelevated:not(:disabled) {
  background-color: var(--accent-default);
  color: var(--accent-default-contrast);
}
.spx-button-accent.mdc-button--raised:not(:disabled) svg, .spx-button-accent.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-accent.mdc-button--outlined:not(:disabled) {
  border-color: var(--accent-lighter);
}
.spx-button-warn.mdc-button:not(:disabled), .spx-button-warn.mdc-button--outlined:not(:disabled) {
  color: var(--warn-default);
}
.spx-button-warn.mdc-button:not(:disabled) svg, .spx-button-warn.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-warn.mdc-button--raised:not(:disabled), .spx-button-warn.mdc-button--unelevated:not(:disabled) {
  background-color: var(--warn-default);
  color: var(--warn-default-contrast);
}
.spx-button-warn.mdc-button--raised:not(:disabled) svg, .spx-button-warn.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-warn.mdc-button--outlined:not(:disabled) {
  border-color: var(--warn-lighter);
}
.spx-button-status-info.mdc-button:not(:disabled), .spx-button-status-info.mdc-button--outlined:not(:disabled) {
  color: var(--status-info);
}
.spx-button-status-info.mdc-button:not(:disabled) svg, .spx-button-status-info.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-info.mdc-button--raised:not(:disabled), .spx-button-status-info.mdc-button--unelevated:not(:disabled) {
  background-color: var(--status-info);
  color: var(--status-info-contrast);
}
.spx-button-status-info.mdc-button--raised:not(:disabled) svg, .spx-button-status-info.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-info.mdc-button--outlined:not(:disabled) {
  border-color: var(--status-info);
}
.spx-button-status-success.mdc-button:not(:disabled), .spx-button-status-success.mdc-button--outlined:not(:disabled) {
  color: var(--status-success);
}
.spx-button-status-success.mdc-button:not(:disabled) svg, .spx-button-status-success.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-success.mdc-button--raised:not(:disabled), .spx-button-status-success.mdc-button--unelevated:not(:disabled) {
  background-color: var(--status-success);
  color: var(--status-success-contrast);
}
.spx-button-status-success.mdc-button--raised:not(:disabled) svg, .spx-button-status-success.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-success.mdc-button--outlined:not(:disabled) {
  border-color: var(--status-success);
}
.spx-button-status-warn.mdc-button:not(:disabled), .spx-button-status-warn.mdc-button--outlined:not(:disabled) {
  color: var(--status-warn);
}
.spx-button-status-warn.mdc-button:not(:disabled) svg, .spx-button-status-warn.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-warn.mdc-button--raised:not(:disabled), .spx-button-status-warn.mdc-button--unelevated:not(:disabled) {
  background-color: var(--status-warn);
  color: var(--status-warn-contrast);
}
.spx-button-status-warn.mdc-button--raised:not(:disabled) svg, .spx-button-status-warn.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-warn.mdc-button--outlined:not(:disabled) {
  border-color: var(--status-warn);
}
.spx-button-status-alert.mdc-button:not(:disabled), .spx-button-status-alert.mdc-button--outlined:not(:disabled) {
  color: var(--status-alert);
}
.spx-button-status-alert.mdc-button:not(:disabled) svg, .spx-button-status-alert.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-alert.mdc-button--raised:not(:disabled), .spx-button-status-alert.mdc-button--unelevated:not(:disabled) {
  background-color: var(--status-alert);
  color: var(--status-alert-contrast);
}
.spx-button-status-alert.mdc-button--raised:not(:disabled) svg, .spx-button-status-alert.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-alert.mdc-button--outlined:not(:disabled) {
  border-color: var(--status-alert);
}
.spx-button-status-error.mdc-button:not(:disabled), .spx-button-status-error.mdc-button--outlined:not(:disabled) {
  color: var(--status-error);
}
.spx-button-status-error.mdc-button:not(:disabled) svg, .spx-button-status-error.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-error.mdc-button--raised:not(:disabled), .spx-button-status-error.mdc-button--unelevated:not(:disabled) {
  background-color: var(--status-error);
  color: var(--status-error-contrast);
}
.spx-button-status-error.mdc-button--raised:not(:disabled) svg, .spx-button-status-error.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-error.mdc-button--outlined:not(:disabled) {
  border-color: var(--status-error);
}
.spx-button-status-absent.mdc-button:not(:disabled), .spx-button-status-absent.mdc-button--outlined:not(:disabled) {
  color: var(--status-absent);
}
.spx-button-status-absent.mdc-button:not(:disabled) svg, .spx-button-status-absent.mdc-button--outlined:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-absent.mdc-button--raised:not(:disabled), .spx-button-status-absent.mdc-button--unelevated:not(:disabled) {
  background-color: var(--status-absent);
  color: var(--status-absent-contrast);
}
.spx-button-status-absent.mdc-button--raised:not(:disabled) svg, .spx-button-status-absent.mdc-button--unelevated:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-button-status-absent.mdc-button--outlined:not(:disabled) {
  border-color: var(--status-absent);
}
.spx-button spx-spinner circle {
  stroke: currentColor;
}

.spx-button * {
  pointer-events: none;
}

.mat-mdc-button[disabled] svg {
  fill: rgba(0, 0, 0, 0.26);
}

spx-icon {
  display: flex;
  justify-content: center;
}

.spx-icon-primary:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--primary-default);
}
.spx-icon-primary:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-accent:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--accent-default);
}
.spx-icon-accent:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-warn:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--warn-default);
}
.spx-icon-warn:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-status-info:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--status-info);
}
.spx-icon-status-info:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-status-success:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--status-success);
}
.spx-icon-status-success:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-status-warn:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--status-warn);
}
.spx-icon-status-warn:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-status-alert:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--status-alert);
}
.spx-icon-status-alert:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-status-error:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--status-error);
}
.spx-icon-status-error:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-status-absent:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
  color: var(--status-absent);
}
.spx-icon-status-absent:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) svg {
  fill: currentColor;
  stroke: currentColor;
}

.spx-icon-button {
  align-items: center;
  display: flex !important;
  justify-content: center;
}
.spx-icon-button .spx-icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.spx-icon-button-primary.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--primary-default);
}
.spx-icon-button-primary.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-primary.mdc-fab:not(:disabled) {
  background-color: var(--primary-default);
  color: var(--primary-default-contrast);
}
.spx-icon-button-primary.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-accent.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--accent-default);
}
.spx-icon-button-accent.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-accent.mdc-fab:not(:disabled) {
  background-color: var(--accent-default);
  color: var(--accent-default-contrast);
}
.spx-icon-button-accent.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-warn.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--warn-default);
}
.spx-icon-button-warn.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-warn.mdc-fab:not(:disabled) {
  background-color: var(--warn-default);
  color: var(--warn-default-contrast);
}
.spx-icon-button-warn.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-info.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--status-info);
}
.spx-icon-button-status-info.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-info.mdc-fab:not(:disabled) {
  background-color: var(--status-info);
  color: var(--status-info-contrast);
}
.spx-icon-button-status-info.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-success.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--status-success);
}
.spx-icon-button-status-success.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-success.mdc-fab:not(:disabled) {
  background-color: var(--status-success);
  color: var(--status-success-contrast);
}
.spx-icon-button-status-success.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-warn.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--status-warn);
}
.spx-icon-button-status-warn.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-warn.mdc-fab:not(:disabled) {
  background-color: var(--status-warn);
  color: var(--status-warn-contrast);
}
.spx-icon-button-status-warn.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-alert.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--status-alert);
}
.spx-icon-button-status-alert.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-alert.mdc-fab:not(:disabled) {
  background-color: var(--status-alert);
  color: var(--status-alert-contrast);
}
.spx-icon-button-status-alert.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-error.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--status-error);
}
.spx-icon-button-status-error.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-error.mdc-fab:not(:disabled) {
  background-color: var(--status-error);
  color: var(--status-error-contrast);
}
.spx-icon-button-status-error.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-absent.mdc-icon-button:not(:disabled) mat-icon {
  color: var(--status-absent);
}
.spx-icon-button-status-absent.mdc-icon-button:not(:disabled) mat-icon svg {
  fill: currentColor;
  stroke: currentColor;
}
.spx-icon-button-status-absent.mdc-fab:not(:disabled) {
  background-color: var(--status-absent);
  color: var(--status-absent-contrast);
}
.spx-icon-button-status-absent.mdc-fab:not(:disabled) svg {
  fill: currentColor;
  stroke: currentColor;
}

/* stylelint-disable selector-class-pattern */
.mdc-snackbar__surface,
.mdc-snackbar__label {
  padding: 0 !important;
}

/* stylelint-enable selector-class-pattern */
.spx-notification.primary {
  background-color: var(--primary-default);
  color: var(--primary-default-contrast);
}
.spx-notification.accent {
  background-color: var(--accent-default);
  color: var(--accent-default-contrast);
}
.spx-notification.warn {
  background-color: var(--warn-default);
  color: var(--warn-default-contrast);
}
.spx-notification.status-info {
  background-color: var(--status-info);
  color: var(--status-info-contrast);
}
.spx-notification.status-success {
  background-color: var(--status-success);
  color: var(--status-success-contrast);
}
.spx-notification.status-warn {
  background-color: var(--status-warn);
  color: var(--status-warn-contrast);
}
.spx-notification.status-alert {
  background-color: var(--status-alert);
  color: var(--status-alert-contrast);
}
.spx-notification.status-error {
  background-color: var(--status-error);
  color: var(--status-error-contrast);
}
.spx-notification.status-absent {
  background-color: var(--status-absent);
  color: var(--status-absent-contrast);
}

.spx-card mat-card-title {
  margin-bottom: 0 !important;
  padding: 0;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.spx-card mat-card-title .spx-icon svg {
  fill: #fff;
  stroke: #fff;
}
.spx-card mat-card-actions {
  margin: 0 !important;
  padding: 16px 16px 16px 20px !important;
}
.spx-card .mat-mdc-card-header-text {
  margin: 0 !important;
  width: 100%;
}

.spx-dialog {
  /* stylelint-disable-next-line selector-class-pattern */
}
.spx-dialog mat-dialog-container {
  padding: 0;
  position: relative;
}
.spx-dialog .dialog__actions {
  background-color: var(--primary-100);
}

.spx-menu {
  padding: 0;
}
.spx-menu mat-divider {
  background-color: var(--app-color-menu) !important;
  border-top-width: 2px;
  margin-bottom: 8px;
  opacity: 0.6;
}

.mat-mdc-nav-list.spx-menu {
  padding: 0;
}

.mat-mdc-nav-list.spx-menu .spx-menu-item {
  background-color: transparent;
  opacity: 0.7;
  /* stylelint-disable-next-line selector-class-pattern */
  height: 50px;
}
.mat-mdc-nav-list.spx-menu .spx-menu-item .spx-menu-item__content {
  color: var(--app-color-contrast) !important;
}

.mat-mdc-nav-list.spx-menu .spx-menu-item--child {
  height: 42px;
}

.mat-mdc-nav-list.spx-menu .spx-menu-item:hover:not(.active) {
  opacity: 1;
  /* stylelint-disable-next-line selector-class-pattern */
  border-radius: 6px;
  border-color: transparent;
}
.mat-mdc-nav-list.spx-menu .spx-menu-item:hover:not(.active) .spx-menu-item__content {
  color: var(--app-color-contrast) !important;
}
.mat-mdc-nav-list.spx-menu .spx-menu-item:hover:not(.active)::before {
  background-color: var(--app-color-menu);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
}

/* stylelint-disable-next-line selector-class-pattern */
.mat-mdc-nav-list.spx-menu .spx-menu-item .spx-menu-item__expand {
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.mat-mdc-nav-list.spx-menu .spx-menu-item .spx-menu-item__expand mat-icon {
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  width: 18px;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.mat-mdc-nav-list.spx-menu .spx-menu-item spx-icon {
  font-size: 22px;
  height: 22px;
  line-height: 22px;
  padding: 0;
  width: 22px;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.mat-mdc-nav-list.spx-menu .spx-menu-item spx-icon mat-icon {
  font-size: 22px;
  height: 22px;
  line-height: 22px;
  width: 22px;
}

.mat-mdc-nav-list.spx-menu .child-items.active {
  opacity: 1;
  /* stylelint-disable-next-line selector-class-pattern */
}
.mat-mdc-nav-list.spx-menu .child-items.active .spx-menu-item__content {
  color: var(--app-color-contrast) !important;
}
.mat-mdc-nav-list.spx-menu .child-items.active::before {
  background-color: var(--app-color-menu);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
}

.mat-mdc-nav-list.spx-menu .spx-menu-item.active {
  opacity: 1;
  /* stylelint-disable-next-line selector-class-pattern */
}
.mat-mdc-nav-list.spx-menu .spx-menu-item.active .spx-menu-item__content {
  color: var(--app-color-contrast) !important;
}
.mat-mdc-nav-list.spx-menu .spx-menu-item.active::before {
  background-color: var(--app-color-menu);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
}

.mat-mdc-nav-list.spx-menu .spx-menu-item.active:not(.spx-menu-item--expanded) {
  border-left: 6px solid var(--app-color-contrast);
  border-radius: 6px;
}

.mat-mdc-nav-list.spx-menu .spx-menu-item:not(.spx-menu-item--parent).active {
  border-left-color: var(--app-color-contrast);
}

.mat-mdc-nav-list.spx-menu .spx-menu-item--child.active {
  opacity: 1;
  /* stylelint-disable-next-line selector-class-pattern */
  border-left-color: var(--app-color-contrast);
}
.mat-mdc-nav-list.spx-menu .spx-menu-item--child.active .spx-menu-item__content {
  color: var(--app-color-contrast) !important;
}
.mat-mdc-nav-list.spx-menu .spx-menu-item--child.active::before {
  background-color: var(--app-color-menu);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
}

.mat-mdc-nav-list.spx-menu spx-menu-item {
  margin: 0 8px;
}

.mat-mdc-nav-list.spx-menu spx-menu-item:not(:last-of-type) {
  margin-bottom: 8px;
}

.mat-mdc-nav-list.spx-menu .child-items spx-menu-item {
  margin: 0;
}

.mat-mdc-nav-list.spx-menu .child-items spx-menu-item:not(:last-of-type) {
  margin-bottom: 4px;
}

.sidenav-container {
  /* stylelint-disable-next-line selector-no-qualifying-type */
}
.sidenav-container mat-sidenav {
  background-color: var(--app-color);
  color: var(--app-color-contrast);
}
.sidenav-container spx-icon-button {
  color: var(--app-color-contrast);
}
.sidenav-container mat-sidenav-content {
  overflow: hidden;
}
.sidenav-container mat-sidenav-content .sidenav__content {
  height: 100%;
  overflow-y: auto;
  background-color: var(--primary-10);
}
.sidenav-container mat-sidenav-content *[content] {
  height: 100%;
}
.sidenav-container mat-sidenav mat-toolbar {
  background-color: transparent;
}
.sidenav-container mat-toolbar {
  height: 64px;
}
.sidenav-container .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.sidenav-container .sidenav__branding mat-toolbar {
  color: var(--app-color-contrast);
}
.sidenav-container .sidenav__items {
  height: 100%;
  overflow-y: auto;
}
.sidenav-container .sidenav__items::-webkit-scrollbar-track {
  background-color: transparent;
}
.sidenav-container .sidenav__items::-webkit-scrollbar-thumb {
  background-color: var(--app-color-menu);
}
.sidenav-container .sidenav__divider {
  background-color: var(--app-color-menu);
  border-top-width: 2px;
}
.sidenav-container spx-menu[footer] {
  margin-bottom: 8px;
  margin-top: 8px;
}

.mat-mdc-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: var(--app-color);
}
.mat-mdc-spinner.mat-status-info circle,
.mat-mdc-progress-spinner.mat-status-info circle {
  stroke: var(--status-info);
}
.mat-mdc-spinner.mat-status-success circle,
.mat-mdc-progress-spinner.mat-status-success circle {
  stroke: var(--status-success);
}
.mat-mdc-spinner.mat-status-warn circle,
.mat-mdc-progress-spinner.mat-status-warn circle {
  stroke: var(--status-warn);
}
.mat-mdc-spinner.mat-status-alert circle,
.mat-mdc-progress-spinner.mat-status-alert circle {
  stroke: var(--status-alert);
}
.mat-mdc-spinner.mat-status-error circle,
.mat-mdc-progress-spinner.mat-status-error circle {
  stroke: var(--status-error);
}

.topbar {
  background-color: var(--app-color) !important;
  color: var(--app-color-contrast) !important;
}

::-webkit-scrollbar-track {
  background-color: var(--primary-300);
  border-radius: 6px;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-500);
  border-radius: 6px;
}

.spx-table {
  /* stylelint-disable-next-line selector-class-pattern */
}
.spx-table tr.spx-row:hover {
  background-color: var(--primary-100);
}
.spx-table tr.spx-row.spx-selected-row,
.spx-table tr.spx-row.spx-expanded-row {
  background-color: var(--primary-200);
}
.spx-table tr.spx-row.spx-selected-row:hover,
.spx-table tr.spx-row.spx-expanded-row:hover {
  background-color: var(--primary-300);
}
.spx-table .mdc-data-table__row {
  height: 48px;
}
.spx-table .mat-mdc-header-cell {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-weight: 500;
}

.range-picker-select-unit {
  min-width: 100px;
}

spx-period-option-list {
  --mat-form-field-container-height: 36px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-filled-with-label-container-padding-top: 6px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
}
spx-period-option-list .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
:root {
  /* stylelint-disable order/order */
  --primary-10: #eee;
  --primary-10-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-50: #fbfbfb;
  --primary-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-100: #f6f6f6;
  --primary-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-200: #f1f1f1;
  --primary-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-300: #e5e5e5;
  --primary-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-400: #c3c3c3;
  --primary-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-500: #a5a5a5;
  --primary-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-600: #7b7b7b;
  --primary-600-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-700: #676767;
  --primary-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-800: #484848;
  --primary-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-900: #262626;
  --primary-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A100: #f6f6f6;
  --primary-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A200: #f1f1f1;
  --primary-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A400: #c3c3c3;
  --primary-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A700: #676767;
  --primary-A700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-50: #ede7f6;
  --accent-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-100: #d1c4e9;
  --accent-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-200: #b49ddb;
  --accent-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-300: #9675cd;
  --accent-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-400: #7f57c2;
  --accent-400-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-500: #683ab7;
  --accent-500-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-600: #5f35b1;
  --accent-600-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-700: #522da8;
  --accent-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-800: #4727a0;
  --accent-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-900: #321b92;
  --accent-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A100: #d4c2f4;
  --accent-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A200: #b899ee;
  --accent-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A400: #834be3;
  --accent-A400-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A700: #4c15ce;
  --accent-A700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-50: #ffebee;
  --warn-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-100: #ffcdd2;
  --warn-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-200: #ef9a9a;
  --warn-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-300: #e57373;
  --warn-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-400: #ef5350;
  --warn-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-500: #f44336;
  --warn-500-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-600: #e53935;
  --warn-600-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-700: #d32f2f;
  --warn-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-800: #c62828;
  --warn-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-900: #b71c1c;
  --warn-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-A100: #ff8a80;
  --warn-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-A200: #ff5252;
  --warn-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-A400: #ff1744;
  --warn-A400-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --warn-A700: #d50000;
  --warn-A700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --status-info: #0c4e99;
  --status-info-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --status-success: #528a04;
  --status-success-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --status-warn: #f29f05;
  --status-warn-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --status-alert: #f26811;
  --status-alert-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --status-error: #e62d1e;
  --status-error-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --status-absent: #454545;
  --status-absent-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-service-point: #eee;
  --primary-service-point-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --default-page-background: #fbfbfb;
  --default-page-background-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --white: #fff;
  --white-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black: #000;
  --black-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a0: rgba(0, 0, 0, 0);
  --black-a0-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a1: rgba(0, 0, 0, 0.1);
  --black-a1-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a2: rgba(0, 0, 0, 0.2);
  --black-a2-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a3: rgba(0, 0, 0, 0.3);
  --black-a3-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a4: rgba(0, 0, 0, 0.4);
  --black-a4-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a5: rgba(0, 0, 0, 0.5);
  --black-a5-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a6: rgba(0, 0, 0, 0.6);
  --black-a6-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a7: rgba(0, 0, 0, 0.7);
  --black-a7-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a8: rgba(0, 0, 0, 0.8);
  --black-a8-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a9: rgba(0, 0, 0, 0.9);
  --black-a9-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --black-a10: black;
  --black-a10-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --note: #fffbf0;
  --note-contrast: #000;
  /* stylelint-enable order/order */
  --primary: var(--primary-800);
  --primary-text: var(--primary-800);
  --primary-contrast: var(--primary-800-contrast);
  --primary-default: var(--primary-800);
  --primary-default-contrast: var(--primary-800-contrast);
  --primary-lighter: var(--primary-300);
  --primary-lighter-contrast: var(--primary-300-contrast);
  --primary-darker: var(--primary-900);
  --primary-darker-contrast: var(--primary-900-contrast);
  --accent: var(--accent-700);
  --accent-text: var(--accent-700);
  --accent-contrast: var(--accent-700-contrast);
  --accent-default: var(--accent-700);
  --accent-default-contrast: var(--accent-700-contrast);
  --accent-lighter: var(--accent-300);
  --accent-lighter-contrast: var(--accent-300-contrast);
  --accent-darker: var(--accent-900);
  --accent-darker-contrast: var(--accent-900-contrast);
  --warn: var(--warn-500);
  --warn-text: var(--warn-500);
  --warn-contrast: var(--warn-500-contrast);
  --warn-default: var(--warn-500);
  --warn-default-contrast: var(--warn-500-contrast);
  --warn-lighter: var(--warn-100);
  --warn-lighter-contrast: var(--warn-100-contrast);
  --warn-darker: var(--warn-700);
  --warn-darker-contrast: var(--warn-700-contrast);
  --app-color: var(--primary);
  --app-color-contrast: var(--primary-contrast);
  --app-color-menu: var(--accent);
  --app-color-menu-contrast: var(--accent-contrast);
}

/* stylelint-disable scss/no-global-function-names */
/**
* Helper function to add default variables to a palette definition:
* $theme-primary: add-vars-to-palette(mat.define-palette($primary-palette), 'primary');
*
* This function will add 'default', 'lighter', 'darker', 'text', 'default-contrast', 'lighter-contrast' and 'darker-contrast' to the map.
*
* @param $palette Angular Material palette
* @param $name the palettes name
* @return a new instance with the added values.
*/
/**
* Color helper function to get a color value from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* text color helper function to get the text value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
*/
/**
* contrast color helper function to get the contrast value of the material color palette
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional). Default: 'primary'
* @param $hue Color hue of the Angular Material Theme palette (optional). Default: 'default'
*/
/**
* Palette helper function to get right color palette from the material theme
*
* @param $theme Angular Material Theme instance
* @param $palette Angular Material Theme palette name (optional)
*/
/**
* Merges the given color-config into the given theme and returns a new theme.
* @param $theme Angular Material Theme instance
* @param $color-config the color-config to merge
* @return the new Angular Material theme instance
*/
/**
* Helper function to get values out of a scss map
*/
/**
* Generates Variables from the given theme like:
* --primary-900-contrast
* --foreground-text
*
* @param $theme Angular Material Theme instance
*/
/**
* Generates Variables from the given color-config like:
* --primary-900-contrast
*
* @param $config Angular Material Theme color-config
* @param $config-name Angular Material Theme color-config name
*/
/**
* Defines a css variables with the given name and value.
* Additionally it calculates the best foreground color and also creates a css variable.
*
* @include define-colors('primary-50', #fbfbfb);
*
* Will create '--primary-50: #fbfbfb' and '--primary-50-contrast': #000'
*
* @param $name the variable to create
* @param $background the color to use
*/
/**
* Sets the needed default vars for the palette like:
* @include theme-defaults-vars('primary', 800, 300, 900);
*
* @param $palette the palettes name
* @param $hue the palettes default hue
* @param $lighter the palettes lighter hue
* @param $darker the palettes darker hue
*/
/**
* Sets the app-color depending on the given palette
*
* @param $palette the palettes name
*/
/* stylelint-enable scss/no-global-function-names */
/* stylelint-disable function-parentheses-space-inside */
/* stylelint-enable function-parentheses-space-inside */
.theme-aro {
  /* stylelint-disable order/order */
  --primary-50: #f7f7f7;
  --primary-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-100: #eee;
  --primary-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-200: #e3e3e3;
  --primary-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-300: #d1d1d1;
  --primary-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-400: #acacac;
  --primary-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-500: #8b8b8b;
  --primary-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-600: #646464;
  --primary-600-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-700: #515151;
  --primary-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-800: #333;
  --primary-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-900: #131313;
  --primary-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A100: #f5f5f5;
  --primary-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A200: #ededed;
  --primary-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A400: #bcbcbc;
  --primary-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A700: #606060;
  --primary-A700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-50: #ffe9ec;
  --accent-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-100: #ffc9cc;
  --accent-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-200: #f19490;
  --accent-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-300: #e76964;
  --accent-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-400: #ef453d;
  --accent-400-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-500: #f2311b;
  --accent-500-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-600: #e4241d;
  --accent-600-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-700: #d21518;
  --accent-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-800: #c60610;
  --accent-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-900: #b70000;
  --accent-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A100: #ffc9ce;
  --accent-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A200: #f89391;
  --accent-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A400: #fa3e3e;
  --accent-A400-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A700: #df0018;
  --accent-A700-contrast: #fff;
  /* stylelint-enable order/order */
  --primary: var(--primary-800);
  --primary-text: var(--primary-800);
  --primary-contrast: var(--primary-800-contrast);
  --primary-default: var(--primary-800);
  --primary-default-contrast: var(--primary-800-contrast);
  --primary-lighter: var(--primary-700);
  --primary-lighter-contrast: var(--primary-700-contrast);
  --primary-darker: var(--primary-900);
  --primary-darker-contrast: var(--primary-900-contrast);
  --accent: var(--accent-800);
  --accent-text: var(--accent-800);
  --accent-contrast: var(--accent-800-contrast);
  --accent-default: var(--accent-800);
  --accent-default-contrast: var(--accent-800-contrast);
  --accent-lighter: var(--accent-700);
  --accent-lighter-contrast: var(--accent-700-contrast);
  --accent-darker: var(--accent-900);
  --accent-darker-contrast: var(--accent-900-contrast);
  --app-color: var(--primary);
  --app-color-contrast: var(--primary-contrast);
}
.theme-aro .logo::before {
  background: url("../assets/img/ARO-White.svg");
}

.theme-milton {
  /* stylelint-disable order/order */
  --primary-50: #fefae0;
  --primary-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-100: #fef3b3;
  --primary-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-200: #fdec80;
  --primary-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-300: #fce44d;
  --primary-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-400: #fbde26;
  --primary-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-500: #fad800;
  --primary-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-600: #f9d400;
  --primary-600-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-700: #f9ce00;
  --primary-700-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-800: #f8c800;
  --primary-800-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-900: #f6bf00;
  --primary-900-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A100: #ffffff;
  --primary-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A200: #fffaea;
  --primary-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A400: #ffecb7;
  --primary-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A700: #ffe69d;
  --primary-A700-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-50: #e1f4fb;
  --accent-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-100: #b3e3f4;
  --accent-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-200: #82d1ee;
  --accent-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-300: #55bfe7;
  --accent-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-400: #35b2e3;
  --accent-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-500: #19a5df;
  --accent-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-600: #1397d1;
  --accent-600-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-700: #0885be;
  --accent-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-800: #0874ab;
  --accent-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-900: #005489;
  --accent-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A100: #bbe1fe;
  --accent-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A200: #8fceff;
  --accent-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A400: #3aacff;
  --accent-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A700: #007dde;
  --accent-A700-contrast: #fff;
  /* stylelint-enable order/order */
  --primary: var(--primary-500);
  --primary-text: var(--primary-500);
  --primary-contrast: var(--primary-500-contrast);
  --primary-default: var(--primary-500);
  --primary-default-contrast: var(--primary-500-contrast);
  --primary-lighter: var(--primary-700);
  --primary-lighter-contrast: var(--primary-700-contrast);
  --primary-darker: var(--primary-900);
  --primary-darker-contrast: var(--primary-900-contrast);
  --accent: var(--accent-900);
  --accent-text: var(--accent-900);
  --accent-contrast: var(--accent-900-contrast);
  --accent-default: var(--accent-900);
  --accent-default-contrast: var(--accent-900-contrast);
  --accent-lighter: var(--accent-700);
  --accent-lighter-contrast: var(--accent-700-contrast);
  --accent-darker: var(--accent-900);
  --accent-darker-contrast: var(--accent-900-contrast);
  --app-color-menu: var(--primary-500);
  --app-color-menu-contrast: var(--primary-500-contrast);
}
.theme-milton .logo::before {
  background: url("../assets/img/logo-lmi.png");
}

.theme-yz {
  /* stylelint-disable order/order */
  --accent-50: #e9f3f5;
  --accent-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-100: #dbf0f5;
  --accent-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-200: #ceedf5;
  --accent-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-300: #b3e8f5;
  --accent-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-400: #97e3f5;
  --accent-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-500: #75d0e5;
  --accent-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-600: #50b3c9;
  --accent-600-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-700: #3092a8;
  --accent-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-800: #176e82;
  --accent-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-900: #094e5e;
  --accent-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A100: #86dbff;
  --accent-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A200: #53ccff;
  --accent-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A400: #20bdff;
  --accent-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A700: #07b5ff;
  --accent-A700-contrast: #000;
  /* stylelint-enable order/order */
  --primary: var(--primary-900);
  --primary-text: var(--primary-900);
  --primary-contrast: var(--primary-900-contrast);
  --primary-default: var(--primary-900);
  --primary-default-contrast: var(--primary-900-contrast);
  --primary-lighter: var(--primary-300);
  --primary-lighter-contrast: var(--primary-300-contrast);
  --primary-darker: var(--primary-900);
  --primary-darker-contrast: var(--primary-900-contrast);
  --accent: var(--accent-800);
  --accent-text: var(--accent-800);
  --accent-contrast: var(--accent-800-contrast);
  --accent-default: var(--accent-800);
  --accent-default-contrast: var(--accent-800-contrast);
  --accent-lighter: var(--accent-600);
  --accent-lighter-contrast: var(--accent-600-contrast);
  --accent-darker: var(--accent-900);
  --accent-darker-contrast: var(--accent-900-contrast);
}
.theme-yz .logo::before {
  background: url("../assets/img/logo-yz.png");
}

.theme-haskel {
  /* stylelint-disable order/order */
  --primary-50: #e9f0f5;
  --primary-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-100: #daeaf5;
  --primary-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-200: #cce4f5;
  --primary-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-300: #aed8f5;
  --primary-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-400: #91cdf5;
  --primary-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-500: #74c1f5;
  --primary-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-600: #57b5f5;
  --primary-600-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-700: #3aaaf5;
  --primary-700-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-800: #1987d2;
  --primary-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-900: #0068ae;
  --primary-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A100: #b1cdff;
  --primary-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A200: #7eacff;
  --primary-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A400: #4b8bff;
  --primary-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A700: #317aff;
  --primary-A700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-50: #eff5e9;
  --accent-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-100: #e7f5da;
  --accent-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-200: #e0f5cb;
  --accent-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-300: #d1f5ae;
  --accent-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-400: #c3f591;
  --accent-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-500: #b4f574;
  --accent-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-600: #a5f557;
  --accent-600-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-700: #85d833;
  --accent-700-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-800: #63b215;
  --accent-800-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-900: #4e8e0e;
  --accent-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A100: #cdffb7;
  --accent-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A200: #a9ff84;
  --accent-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A400: #85ff51;
  --accent-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A700: #73ff37;
  --accent-A700-contrast: #000;
  /* stylelint-enable order/order */
  --primary: var(--primary-900);
  --primary-text: var(--primary-900);
  --primary-contrast: var(--primary-900-contrast);
  --primary-default: var(--primary-900);
  --primary-default-contrast: var(--primary-900-contrast);
  --primary-lighter: var(--primary-700);
  --primary-lighter-contrast: var(--primary-700-contrast);
  --primary-darker: var(--primary-900);
  --primary-darker-contrast: var(--primary-900-contrast);
  --accent: var(--accent-900);
  --accent-text: var(--accent-900);
  --accent-contrast: var(--accent-900-contrast);
  --accent-default: var(--accent-900);
  --accent-default-contrast: var(--accent-900-contrast);
  --accent-lighter: var(--accent-700);
  --accent-lighter-contrast: var(--accent-700-contrast);
  --accent-darker: var(--accent-900);
  --accent-darker-contrast: var(--accent-900-contrast);
  --app-color-menu: var(--primary-800);
  --app-color-menu-contrast: var(--primary-800-contrast);
}
.theme-haskel .logo::before {
  background: url("../assets/img/logo-haskel.png");
}

.theme-iris {
  /* stylelint-disable order/order */
  --accent-50: #f5e9e9;
  --accent-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-100: #f5dcda;
  --accent-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-200: #f5cfcc;
  --accent-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-300: #f5b5b0;
  --accent-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-400: #f59a93;
  --accent-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-500: #f58077;
  --accent-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-600: #f5655a;
  --accent-600-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-700: #f54b3e;
  --accent-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-800: #d42a1d;
  --accent-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-900: #b01d12;
  --accent-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A100: #ffe5e5;
  --accent-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A200: #ffb3b2;
  --accent-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A400: #ff817f;
  --accent-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A700: #ff6865;
  --accent-A700-contrast: #000;
  /* stylelint-enable order/order */
  --primary: var(--primary-800);
  --primary-text: var(--primary-800);
  --primary-contrast: var(--primary-800-contrast);
  --primary-default: var(--primary-800);
  --primary-default-contrast: var(--primary-800-contrast);
  --primary-lighter: var(--primary-700);
  --primary-lighter-contrast: var(--primary-700-contrast);
  --primary-darker: var(--primary-900);
  --primary-darker-contrast: var(--primary-900-contrast);
  --accent: var(--accent-800);
  --accent-text: var(--accent-800);
  --accent-contrast: var(--accent-800-contrast);
  --accent-default: var(--accent-800);
  --accent-default-contrast: var(--accent-800-contrast);
  --accent-lighter: var(--accent-700);
  --accent-lighter-contrast: var(--accent-700-contrast);
  --accent-darker: var(--accent-900);
  --accent-darker-contrast: var(--accent-900-contrast);
}
.theme-iris .logo::before {
  background: url("../assets/img/logo-iris.png");
}

.theme-albin {
  /* stylelint-disable order/order */
  --accent-50: #f3f5e9;
  --accent-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-100: #f1f5db;
  --accent-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-200: #eff5ce;
  --accent-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-300: #ecf5b3;
  --accent-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-400: #e8f598;
  --accent-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-500: #e5f57e;
  --accent-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-600: #cadc59;
  --accent-600-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-700: #a9bb37;
  --accent-700-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-800: #84951c;
  --accent-800-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-900: #63710b;
  --accent-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A100: #f5ffca;
  --accent-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A200: #eaff97;
  --accent-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A400: #e0ff64;
  --accent-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A700: #dbff4b;
  --accent-A700-contrast: #000;
  /* stylelint-enable order/order */
  --primary: var(--primary-800);
  --primary-text: var(--primary-800);
  --primary-contrast: var(--primary-800-contrast);
  --primary-default: var(--primary-800);
  --primary-default-contrast: var(--primary-800-contrast);
  --primary-lighter: var(--primary-700);
  --primary-lighter-contrast: var(--primary-700-contrast);
  --primary-darker: var(--primary-900);
  --primary-darker-contrast: var(--primary-900-contrast);
  --accent: var(--accent-700);
  --accent-text: var(--accent-700);
  --accent-contrast: var(--accent-700-contrast);
  --accent-default: var(--accent-700);
  --accent-default-contrast: var(--accent-700-contrast);
  --accent-lighter: var(--accent-500);
  --accent-lighter-contrast: var(--accent-500-contrast);
  --accent-darker: var(--accent-900);
  --accent-darker-contrast: var(--accent-900-contrast);
  --app-color-menu: var(--accent-900);
  --app-color-menu-contrast: var(--accent-900-contrast);
}
.theme-albin .logo::before {
  background: url("../assets/img/logo-albin.png");
}

.theme-thomas {
  /* stylelint-disable order/order */
  --primary-50: #f7f7f7;
  --primary-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-100: #eee;
  --primary-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-200: #e3e3e3;
  --primary-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-300: #d1d1d1;
  --primary-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-400: #acacac;
  --primary-400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-500: #8b8b8b;
  --primary-500-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-600: #646464;
  --primary-600-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-700: #515151;
  --primary-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-800: #333;
  --primary-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-900: #131313;
  --primary-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A100: #f5f5f5;
  --primary-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A200: #ededed;
  --primary-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A400: #bcbcbc;
  --primary-A400-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --primary-A700: #606060;
  --primary-A700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-50: #ffe9ec;
  --accent-50-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-100: #ffc9cc;
  --accent-100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-200: #f19490;
  --accent-200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-300: #e76964;
  --accent-300-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-400: #ef453d;
  --accent-400-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-500: #f2311b;
  --accent-500-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-600: #e4241d;
  --accent-600-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-700: #d21518;
  --accent-700-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-800: #c60610;
  --accent-800-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-900: #b70000;
  --accent-900-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A100: #ffc9ce;
  --accent-A100-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A200: #f89391;
  --accent-A200-contrast: #000;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A400: #fa3e3e;
  --accent-A400-contrast: #fff;
  /* stylelint-enable order/order */
  /* stylelint-disable order/order */
  --accent-A700: #df0018;
  --accent-A700-contrast: #fff;
  /* stylelint-enable order/order */
  --primary: var(--primary-800);
  --primary-text: var(--primary-800);
  --primary-contrast: var(--primary-800-contrast);
  --primary-default: var(--primary-800);
  --primary-default-contrast: var(--primary-800-contrast);
  --primary-lighter: var(--primary-700);
  --primary-lighter-contrast: var(--primary-700-contrast);
  --primary-darker: var(--primary-900);
  --primary-darker-contrast: var(--primary-900-contrast);
  --accent: var(--accent-800);
  --accent-text: var(--accent-800);
  --accent-contrast: var(--accent-800-contrast);
  --accent-default: var(--accent-800);
  --accent-default-contrast: var(--accent-800-contrast);
  --accent-lighter: var(--accent-700);
  --accent-lighter-contrast: var(--accent-700-contrast);
  --accent-darker: var(--accent-900);
  --accent-darker-contrast: var(--accent-900-contrast);
  --app-color-menu: var(--accent-800);
  --app-color-menu-contrast: var(--accent-800-contrast);
  --app-color: var(--primary);
  --app-color-contrast: var(--primary-contrast);
}
.theme-thomas .logo::before {
  background: url("../assets/img/logo-thomas.png");
}

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
  margin: 0;
}

.spx-typography h1,
.spx-typography h2 {
  margin-bottom: 8px;
}

/* stylelint-disable stylelint-plugins/no-font-properties */
/* cyrillic-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2") format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("KFOlCnqEu92Fr1MmSU5fBBc4.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("KFOmCnqEu92Fr1Mu72xKOzY.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("KFOmCnqEu92Fr1Mu5mxKOzY.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("KFOmCnqEu92Fr1Mu7mxKOzY.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("KFOmCnqEu92Fr1Mu4WxKOzY.woff2") format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("KFOmCnqEu92Fr1Mu7WxKOzY.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("KFOmCnqEu92Fr1Mu7GxKOzY.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("KFOmCnqEu92Fr1Mu4mxK.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2") format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("KFOlCnqEu92Fr1MmEU9fBBc4.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2") format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("KFOlCnqEu92Fr1MmWUlfBBc4.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* stylelint-enable stylelint-plugins/no-font-properties */
/* stylelint-disable stylelint-plugins/no-font-properties */
/* fallback */
@font-face {
  font-display: block;
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}
/* fallback */
@font-face {
  font-display: block;
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2") format("woff2");
}
.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: "Material Icons";
  font-feature-settings: "liga";
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

.material-icons-outlined {
  direction: ltr;
  display: inline-block;
  font-family: "Material Icons Outlined";
  font-feature-settings: "liga";
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

/* stylelint-enable stylelint-plugins/no-font-properties */
/* stylelint-disable function-parentheses-space-inside */
/* stylelint-enable function-parentheses-space-inside */
mat-form-field {
  width: 100%;
}

mat-radio-button {
  margin: 0 0 8px 4px;
}

mat-label {
  margin-bottom: 4px;
}

.spx-button--full-width .spx-button {
  width: 100%;
}

.home-buttons .spx-button {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
  height: 160px !important;
  width: 100%;
}
.home-buttons .spx-button .mat-icon {
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  font-size: 45px !important;
  height: 45px !important;
  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: 45px !important;
  margin: 0 0 8px !important;
  width: 45px !important;
}

@media screen and (max-width: 760px) {
  .spx-dialog {
    height: 100%;
    max-width: 100% !important;
  }
}
.button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  padding: 16px 0 0 0;
}

.responsive-button {
  width: auto; /* Default width for desktop */
  display: inline-block; /* Ensure it only takes up as much space as needed */
}

@media (max-width: 768px) {
  .responsive-button {
    width: 100% !important; /* Full width for mobile */
    display: block;
  }
}
.ps-widget[ps-sku],
.ps-widget[data-ps-sku],
button.ps-online-buy-button.available {
  background-color: var(--accent-default) !important;
  color: var(--accent-default-contrast) !important;
  border-radius: 4px !important;
  border-color: var(--accent-default) !important;
}

.ps-widget[ps-sku].ps-disabled,
.ps-widget[data-ps-sku].ps-disabled {
  display: none !important;
}

.ps-online-tab .ps-no-results > span > strong {
  color: var(--accent-default) !important;
}

.ps-ribbon {
  border-top-color: var(--accent-default) !important;
  border-bottom-color: var(--accent-default) !important;
}

.ps-location > div > span.ps-map-geolocation-button > svg > path,
.ps-location > div > span.ps-map-location-button > svg > path {
  fill: var(--accent-default) !important;
}