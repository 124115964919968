/* stylelint-disable stylelint-plugins/no-hardcoded-colors */
/* stylelint-disable stylelint-plugins/no-font-properties */
@import '../core/helpers';

/**
* BUTTON THEMING
*/
@mixin spx-button($theme, $status) {
  .spx-button {
    background-color: #fff;

    /* stylelint-disable selector-class-pattern */
    .mdc-button,
    .mdc-button__label {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .mdc-button__label {
      line-height: 1;
    }
    /* stylelint-enable selector-class-pattern */

    mat-icon,
    .spx-icon {
      font-size: 16px;
      height: 16px;
      line-height: 16px;
      width: 16px;
    }

    &-primary {
      @include spx-button-color(color($theme), color($theme, 'primary', 'lighter'), contrast-color($theme));
    }

    &-accent {
      @include spx-button-color(color($theme, 'accent'), color($theme, 'accent', 'lighter'), contrast-color($theme, 'accent'));
    }

    &-warn {
      @include spx-button-color(color($theme, 'warn'), color($theme, 'warn', 'lighter'), contrast-color($theme, 'warn'));
    }

    @each $statusKey in $status {
      &-status-#{$statusKey} {
        @include spx-button-color(
          color($theme, 'status', $statusKey),
          color($theme, 'status', $statusKey),
          color($theme, 'status', #{$statusKey}-contrast)
        );
      }
    }

    spx-spinner circle {
      stroke: currentColor;
    }
  }

  // disables all click events below a spx-button
  .spx-button * {
    pointer-events: none;
  }

  // fill icons with disabled color of Material
  .mat-mdc-button[disabled] {
    svg {
      fill: rgba(0, 0, 0, 0.26);
    }
  }
}

/**
* Mixin to set the scss properties for the material buttons
*/
@mixin spx-button-color($color, $border-color, $textColor) {
  &.mdc-button:not(:disabled),
  &.mdc-button--outlined:not(:disabled) {
    color: $color;

    svg {
      fill: currentColor;
      stroke: currentColor;
    }
  }

  &.mdc-button--raised:not(:disabled),
  &.mdc-button--unelevated:not(:disabled) {
    background-color: $color;
    color: $textColor;

    svg {
      fill: currentColor;
      stroke: currentColor;
    }
  }

  &.mdc-button--outlined:not(:disabled) {
    border-color: $border-color;
  }
}
/* stylelint-enable stylelint-plugins/no-hardcoded-colors */
/* stylelint-enable stylelint-plugins/no-font-properties */
