/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;
@import '../defaults/_typography-config';

mat-form-field label {
  padding-right: 4px;
}

mat-form-field.ng-dirty {
  label {
    font-style: italic;
  }
}

a {
  text-decoration: none;
}

mat-card {
  padding: 16px;
}

.mat-mdc-form-field-infix {
  align-items: center;
  display: flex;
}

mat-divider {
  margin-left: 0 !important;
}

.mat-mdc-card-header-text {
  margin: 0 16px;
}

.mat-mdc-snack-bar-label.mdc-snackbar__label {
  margin: auto 8px;
}

spx-range-selector-input {
  @include mat.form-field-density(-3);

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

mat-button-toggle {
  align-items: center;
  display: flex;
  height: 30px;

  /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
  line-height: 30px;
}

mat-button-toggle .mat-button-toggle-label-content {
  @include mat.m2-typography-level($typography-config, 'body3');
}

.mat-mdc-menu-item-text,
.mat-mdc-form-field-icon-suffix,
.mat-mdc-chip-action-label,
mat-option > span,
.mat-mdc-list-item-unscoped-content {
  display: flex;
}

mat-option > span {
  align-items: center;
}

.mat-mdc-option .mat-icon.spx-icon {
  margin-right: 8px;
}

.mat-mdc-dialog-container .mdc-dialog__content.mat-mdc-dialog-content {
  color: unset;
}

mat-card-subtitle.mat-mdc-card-subtitle {
  @include mat.m2-typography-level($typography-config, 's1');
}

.mat-mdc-autocomplete-panel {
  padding-top: 0;
}

.mat-mdc-simple-snack-bar {
  padding: 1rem;
}

.mat-content {
  @include mat.m2-typography-level($typography-config, 's1');
}

.mat-mdc-menu-item-text {
  align-items: center;
  flex-direction: row;
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 16px;
}

// primary colors
[color='primary'] {
  .mdc-tab:hover {
    background-color: var(--primary-50) !important;
  }

  .mdc-tab-indicator--active {
    color: var(--primary) !important;
    opacity: 1;
  }

  mat-button-toggle:not(.mat-button-toggle-disabled) {
    color: var(--primary) !important;
  }

  .mat-button-toggle-checked {
    background-color: var(--primary-300) !important;
    color: var(--primary-300-contrast) !important;
  }

  .mdc-checkbox__checkmark {
    color: var(--primary-contrast) !important;
  }

  .mdc-checkbox__mixedmark {
    border-color: var(--primary-contrast) !important;
  }
}

// accent colors
[color='accent'] {
  .mdc-tab:hover {
    background-color: var(--accent-50) !important;
  }

  .mdc-tab-indicator--active {
    color: var(--accent) !important;
    opacity: 1;
  }

  mat-button-toggle:not(.mat-button-toggle-disabled) {
    color: var(--accent) !important;
  }

  .mat-button-toggle-checked {
    background-color: var(--accent-300) !important;
    color: var(--accent-300-contrast) !important;
  }

  .mdc-checkbox__checkmark {
    color: var(--accent-contrast) !important;
  }

  .mdc-checkbox__mixedmark {
    border-color: var(--accent-contrast) !important;
  }

  // DEVELOPER NOTE
  //
  // I don't know why, but for accent color the material css variables are missing
  // Is this an overwrite for the colors. Maybe this is an issue within material? Maybe it will be fixed with a newer version?
  //
  &.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background,
  &[ng-reflect-indeterminate='true'] .mdc-checkbox__background {
    background-color: var(--accent) !important;
  }

  &.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background,
  &[ng-reflect-indeterminate='true'] .mdc-checkbox__background {
    border-color: var(--accent) !important;
  }
}

// warn colors
[color='warn'] {
  .mdc-tab:hover {
    background-color: var(--warn-50) !important;
  }

  .mdc-tab-indicator--active {
    color: var(--warn) !important;
    opacity: 1;
  }

  mat-button-toggle:not(.mat-button-toggle-disabled) {
    color: var(--warn) !important;
  }

  .mat-button-toggle-checked {
    background-color: var(--warn-300) !important;
    color: var(--warn-300-contrast) !important;
  }

  .mdc-checkbox__checkmark {
    color: var(--warn-contrast) !important;
  }

  .mdc-checkbox__mixedmark {
    border-color: var(--warn-contrast) !important;
  }
}
/* stylelint-enable selector-class-pattern */

// DEVELOPER NOTE
//
// This is a workaround for this issue: https://github.com/h2qutc/angular-material-components/issues/348
//
// This should be removable, when this issue is fixed

.mat-calendar-body-cell-content {
  border: 0 !important;
}

.mat-datepicker-content {
  /* stylelint-disable-next-line stylelint-plugins/no-hardcoded-colors */
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
  background-color: var(--primary-contrast);
  color: var(--primary);
}

.mat-primary {
  .mat-calendar-body-selected {
    background-color: var(--primary);
    color: var(--primary-contrast);
  }

  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):hover {
    background-color: var(--primary-300);
    color: var(--primary-300-contrast);
  }
}

.mat-accent {
  .mat-calendar-body-selected {
    background-color: var(--accent);
    color: var(--accent-contrast);
  }

  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):hover {
    background-color: var(--accent-300);
    color: var(--accent-300-contrast);
  }
}

.mat-warn {
  .mat-calendar-body-selected {
    background-color: var(--warn);
    color: var(--warn-contrast);
  }

  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):hover {
    background-color: var(--warn-300);
    color: var(--warn-300-contrast);
  }
}

/* Material uses color.adjust to add opacity to progress-track-color.
   Thus we use css variables in theming and sass functions are not able to use them
   we have to set them here. Otherwise fore- and background would be the same
   @see https://github.com/angular/components/blob/422e85d1524f8eeff2425e403702fbfc23a2cc2e/src/material/core/tokens/m2/mdc/_linear-progress.scss#L35
*/
mat-progress-bar.mat-mdc-progress-bar {
  &.mat-primary {
    --mdc-linear-progress-track-color: var(--primary-lighter);
  }

  &.mat-warn {
    --mdc-linear-progress-track-color: var(--warn-lighter);
  }

  &.mat-accent {
    --mdc-linear-progress-track-color: var(--accent-lighter);
  }
}

/**
* Material 2 legacy is missing this class since Update to angular material 19.
*/
.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}
