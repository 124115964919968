@import '../core/helpers';

@mixin spx-scrollbar($theme) {
  ::-webkit-scrollbar-track {
    background-color: color($theme, 'primary', 300);
    border-radius: 6px;
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: color($theme, 'primary', 500);
    border-radius: 6px;
  }
}
