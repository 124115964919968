@import '../core/helpers';

@mixin spx-dialog($theme, $status) {
  .spx-dialog {
    mat-dialog-container {
      // necessary for loading bar at the top of the dialog
      padding: 0;
      position: relative;
    }

    // styling the actions section
    /* stylelint-disable-next-line selector-class-pattern */
    .dialog__actions {
      background-color: color($theme, 'primary', 100);
    }
  }
}
