@use '@angular/material' as mat;

@import 'scss/default-theme';
@import 'scss/core/helpers';
@import 'scss/defaults/typography-config';

$custom-typography: mat.m2-define-typography-config();

.theme-aro {
  @include define-colors('primary-50', #f7f7f7);
  @include define-colors('primary-100', #eee);
  @include define-colors('primary-200', #e3e3e3);
  @include define-colors('primary-300', #d1d1d1);
  @include define-colors('primary-400', #acacac);
  @include define-colors('primary-500', #8b8b8b);
  @include define-colors('primary-600', #646464);
  @include define-colors('primary-700', #515151);
  @include define-colors('primary-800', #333);
  @include define-colors('primary-900', #131313);
  @include define-colors('primary-A100', #f5f5f5);
  @include define-colors('primary-A200', #ededed);
  @include define-colors('primary-A400', #bcbcbc);
  @include define-colors('primary-A700', #606060);
  @include define-colors('accent-50', #ffe9ec);
  @include define-colors('accent-100', #ffc9cc);
  @include define-colors('accent-200', #f19490);
  @include define-colors('accent-300', #e76964);
  @include define-colors('accent-400', #ef453d);
  @include define-colors('accent-500', #f2311b);
  @include define-colors('accent-600', #e4241d);
  @include define-colors('accent-700', #d21518);
  @include define-colors('accent-800', #c60610);
  @include define-colors('accent-900', #b70000);
  @include define-colors('accent-A100', #ffc9ce);
  @include define-colors('accent-A200', #f89391);
  @include define-colors('accent-A400', #fa3e3e);
  @include define-colors('accent-A700', #df0018);

  @include theme-defaults-vars('primary', 800, 700, 900);
  @include theme-defaults-vars('accent', 800, 700, 900);

  @include app-color('primary');

  .logo::before {
    background: url('../assets/img/ARO-White.svg');
  }
}

.theme-milton {
  @include define-colors('primary-50', #fefae0);
  @include define-colors('primary-100', #fef3b3);
  @include define-colors('primary-200', #fdec80);
  @include define-colors('primary-300', #fce44d);
  @include define-colors('primary-400', #fbde26);
  @include define-colors('primary-500', #fad800);
  @include define-colors('primary-600', #f9d400);
  @include define-colors('primary-700', #f9ce00);
  @include define-colors('primary-800', #f8c800);
  @include define-colors('primary-900', #f6bf00);
  @include define-colors('primary-A100', #ffffff);
  @include define-colors('primary-A200', #fffaea);
  @include define-colors('primary-A400', #ffecb7);
  @include define-colors('primary-A700', #ffe69d);
  @include define-colors('accent-50', #e1f4fb);
  @include define-colors('accent-100', #b3e3f4);
  @include define-colors('accent-200', #82d1ee);
  @include define-colors('accent-300', #55bfe7);
  @include define-colors('accent-400', #35b2e3);
  @include define-colors('accent-500', #19a5df);
  @include define-colors('accent-600', #1397d1);
  @include define-colors('accent-700', #0885be);
  @include define-colors('accent-800', #0874ab);
  @include define-colors('accent-900', #005489);
  @include define-colors('accent-A100', #bbe1fe);
  @include define-colors('accent-A200', #8fceff);
  @include define-colors('accent-A400', #3aacff);
  @include define-colors('accent-A700', #007dde);

  @include theme-defaults-vars('primary', 500, 700, 900);
  @include theme-defaults-vars('accent', 900, 700, 900);

  @include menu-color('primary-500');

  .logo::before {
    background: url('../assets/img/logo-lmi.png');
  }
}

.theme-yz {
  @include define-colors('accent-50', #e9f3f5);
  @include define-colors('accent-100', #dbf0f5);
  @include define-colors('accent-200', #ceedf5);
  @include define-colors('accent-300', #b3e8f5);
  @include define-colors('accent-400', #97e3f5);
  @include define-colors('accent-500', #75d0e5);
  @include define-colors('accent-600', #50b3c9);
  @include define-colors('accent-700', #3092a8);
  @include define-colors('accent-800', #176e82);
  @include define-colors('accent-900', #094e5e);
  @include define-colors('accent-A100', #86dbff);
  @include define-colors('accent-A200', #53ccff);
  @include define-colors('accent-A400', #20bdff);
  @include define-colors('accent-A700', #07b5ff);

  @include theme-defaults-vars('primary', 900, 300, 900);
  @include theme-defaults-vars('accent', 800, 600, 900);

  .logo::before {
    background: url('../assets/img/logo-yz.png');
  }
}

.theme-haskel {
  @include define-colors('primary-50', #e9f0f5);
  @include define-colors('primary-100', #daeaf5);
  @include define-colors('primary-200', #cce4f5);
  @include define-colors('primary-300', #aed8f5);
  @include define-colors('primary-400', #91cdf5);
  @include define-colors('primary-500', #74c1f5);
  @include define-colors('primary-600', #57b5f5);
  @include define-colors('primary-700', #3aaaf5);
  @include define-colors('primary-800', #1987d2);
  @include define-colors('primary-900', #0068ae);
  @include define-colors('primary-A100', #b1cdff);
  @include define-colors('primary-A200', #7eacff);
  @include define-colors('primary-A400', #4b8bff);
  @include define-colors('primary-A700', #317aff);

  @include define-colors('accent-50', #eff5e9);
  @include define-colors('accent-100', #e7f5da);
  @include define-colors('accent-200', #e0f5cb);
  @include define-colors('accent-300', #d1f5ae);
  @include define-colors('accent-400', #c3f591);
  @include define-colors('accent-500', #b4f574);
  @include define-colors('accent-600', #a5f557);
  @include define-colors('accent-700', #85d833);
  @include define-colors('accent-800', #63b215);
  @include define-colors('accent-900', #4e8e0e);
  @include define-colors('accent-A100', #cdffb7);
  @include define-colors('accent-A200', #a9ff84);
  @include define-colors('accent-A400', #85ff51);
  @include define-colors('accent-A700', #73ff37);

  @include theme-defaults-vars('primary', 900, 700, 900);
  @include theme-defaults-vars('accent', 900, 700, 900);

  @include menu-color('primary-800');

  .logo::before {
    background: url('../assets/img/logo-haskel.png');
  }
}

.theme-iris {
  @include define-colors('accent-50', #f5e9e9);
  @include define-colors('accent-100', #f5dcda);
  @include define-colors('accent-200', #f5cfcc);
  @include define-colors('accent-300', #f5b5b0);
  @include define-colors('accent-400', #f59a93);
  @include define-colors('accent-500', #f58077);
  @include define-colors('accent-600', #f5655a);
  @include define-colors('accent-700', #f54b3e);
  @include define-colors('accent-800', #d42a1d);
  @include define-colors('accent-900', #b01d12);
  @include define-colors('accent-A100', #ffe5e5);
  @include define-colors('accent-A200', #ffb3b2);
  @include define-colors('accent-A400', #ff817f);
  @include define-colors('accent-A700', #ff6865);

  @include theme-defaults-vars('primary', 800, 700, 900);
  @include theme-defaults-vars('accent', 800, 700, 900);

  .logo::before {
    background: url('../assets/img/logo-iris.png');
  }
}

.theme-albin {
  @include define-colors('accent-50', #f3f5e9);
  @include define-colors('accent-100', #f1f5db);
  @include define-colors('accent-200', #eff5ce);
  @include define-colors('accent-300', #ecf5b3);
  @include define-colors('accent-400', #e8f598);
  @include define-colors('accent-500', #e5f57e);
  @include define-colors('accent-600', #cadc59);
  @include define-colors('accent-700', #a9bb37);
  @include define-colors('accent-800', #84951c);
  @include define-colors('accent-900', #63710b);
  @include define-colors('accent-A100', #f5ffca);
  @include define-colors('accent-A200', #eaff97);
  @include define-colors('accent-A400', #e0ff64);
  @include define-colors('accent-A700', #dbff4b);

  @include theme-defaults-vars('primary', 800, 700, 900);
  @include theme-defaults-vars('accent', 700, 500, 900);

  @include menu-color('accent-900');

  .logo::before {
    background: url('../assets/img/logo-albin.png');
  }
}

.theme-thomas {
  @include define-colors('primary-50', #f7f7f7);
  @include define-colors('primary-100', #eee);
  @include define-colors('primary-200', #e3e3e3);
  @include define-colors('primary-300', #d1d1d1);
  @include define-colors('primary-400', #acacac);
  @include define-colors('primary-500', #8b8b8b);
  @include define-colors('primary-600', #646464);
  @include define-colors('primary-700', #515151);
  @include define-colors('primary-800', #333);
  @include define-colors('primary-900', #131313);
  @include define-colors('primary-A100', #f5f5f5);
  @include define-colors('primary-A200', #ededed);
  @include define-colors('primary-A400', #bcbcbc);
  @include define-colors('primary-A700', #606060);
  @include define-colors('accent-50', #ffe9ec);
  @include define-colors('accent-100', #ffc9cc);
  @include define-colors('accent-200', #f19490);
  @include define-colors('accent-300', #e76964);
  @include define-colors('accent-400', #ef453d);
  @include define-colors('accent-500', #f2311b);
  @include define-colors('accent-600', #e4241d);
  @include define-colors('accent-700', #d21518);
  @include define-colors('accent-800', #c60610);
  @include define-colors('accent-900', #b70000);
  @include define-colors('accent-A100', #ffc9ce);
  @include define-colors('accent-A200', #f89391);
  @include define-colors('accent-A400', #fa3e3e);
  @include define-colors('accent-A700', #df0018);

  @include theme-defaults-vars('primary', 800, 700, 900);
  @include theme-defaults-vars('accent', 800, 700, 900);

  @include menu-color('accent-800');

  @include app-color('primary');

  .logo::before {
    background: url('../assets/img/logo-thomas.png');
  }
}

html,
body {
  height: 100%;
}

body {
  @include mat.m2-typography-level($typography-config, 'body1');
  margin: 0;
}
