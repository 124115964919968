@use '@angular/material' as mat;
@import '../core/helpers';
@import '../defaults/typography-config';

@mixin spx-table($theme) {
  .spx-table {
    tr.spx-row:hover {
      background-color: color($theme, 'primary', 100);
    }

    tr.spx-row.spx-selected-row,
    tr.spx-row.spx-expanded-row {
      background-color: color($theme, 'primary', 200);
    }

    tr.spx-row.spx-selected-row:hover,
    tr.spx-row.spx-expanded-row:hover {
      background-color: color($theme, 'primary', 300);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-data-table__row {
      height: 48px;
    }

    .mat-mdc-header-cell {
      @include mat.m2-typography-level($typography-config, 'caption');
      /* stylelint-disable-next-line stylelint-plugins/no-font-properties */
      font-weight: mat.m2-font-weight($typography-config, 'body2-medium');
    }
  }
}
