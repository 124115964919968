@mixin spx-spinner($theme) {
  .mat-mdc-spinner,
  .mat-mdc-progress-spinner {
    circle {
      stroke: var(--app-color);
    }

    &.mat-status-info circle {
      stroke: var(--status-info);
    }

    &.mat-status-success circle {
      stroke: var(--status-success);
    }

    &.mat-status-warn circle {
      stroke: var(--status-warn);
    }

    &.mat-status-alert circle {
      stroke: var(--status-alert);
    }

    &.mat-status-error circle {
      stroke: var(--status-error);
    }
  }
}
