.ps-widget[ps-sku],
.ps-widget[data-ps-sku],
button.ps-online-buy-button.available {
  background-color: var(--accent-default) !important;
  color: var(--accent-default-contrast) !important;
  border-radius: 4px !important;
  border-color: var(--accent-default) !important;
}

.ps-widget[ps-sku].ps-disabled,
.ps-widget[data-ps-sku].ps-disabled {
  display: none !important;
}

.ps-online-tab .ps-no-results > span > strong {
  color: var(--accent-default) !important;
}

.ps-ribbon {
  border-top-color: var(--accent-default) !important;
  border-bottom-color: var(--accent-default) !important;
}

.ps-location > div > span.ps-map-geolocation-button > svg > path,
.ps-location > div > span.ps-map-location-button > svg > path {
  fill: var(--accent-default) !important;
}
