@import 'core/helpers';
@import 'components/button';
@import 'components/icon-button';
@import 'components/notification';
@import 'components/dialog';
@import 'components/icon';
@import 'components/card';
@import 'components/menu';
@import 'components/password';
@import 'components/range-selector';
@import 'components/sidenav';
@import 'components/spinner';
@import 'components/topbar';
@import 'components/scrollbar';
@import 'components/typography';
@import 'components/table';

$dynamic-defaults: (
  status: (
    info: var(--status-info),
    info-contrast: var(--status-info-contrast),
    success: var(--status-success),
    success-contrast: var(--status-success-contrast),
    warn: var(--status-warn),
    warn-contrast: var(--status-warn-contrast),
    alert: var(--status-alert),
    alert-contrast: var(--status-alert-contrast),
    error: var(--status-error),
    error-contrast: var(--status-error-contrast),
    absent: var(--status-absent),
    absent-contrast: var(--status-absent-contrast),
  ),
  app-color: (
    menu: var(--app-color-menu),
    menu-contrast: var(--app-color-menu-contrast),
    default: var(--app-color),
    default-contrast: var(--app-color-contrast),
  ),
);

/**
* MAIN SEEPEX THEMING
*/
@function add-spx-theme-config($theme) {
  /**
  * Default status colors
  */
  $defaults: (
    status: (
      info: #0c4e99,
      info-contrast: white,
      success: #528a04,
      success-contrast: white,
      warn: #f29f05,
      warn-contrast: white,
      alert: #f26811,
      alert-contrast: white,
      error: #e62d1e,
      error-contrast: white,
    ),
    app-color: (
      menu: color($theme, 'accent'),
      menu-contrast: contrast-color($theme, 'accent'),
      default: color($theme, 'primary'),
      default-contrast: contrast-color($theme, 'primary'),
    ),
  );
  @return add-color-config($theme, $defaults);
}

@function add-dynamic-spx-theme-config($theme) {
  @return add-color-config($theme, $dynamic-defaults);
}

@mixin spx-theme($theme, $typography-config) {
  $status-name: 'info', 'success', 'warn', 'alert', 'error', 'absent';

  @include spx-typography($typography-config);
  @include spx-button($theme, $status-name);
  @include spx-icon($theme, $status-name);
  @include spx-icon-button($theme, $status-name);
  @include spx-notification($theme, $status-name);
  @include spx-card($theme, $status-name);
  @include spx-dialog($theme, $status-name);
  @include spx-menu($theme, $status-name);
  @include spx-sidenav($theme, $status-name);
  @include spx-spinner($theme);
  @include spx-topbar($theme);
  @include spx-scrollbar($theme);
  @include spx-table($theme);
  @include spx-range-selector($theme);
}
