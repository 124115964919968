/* stylelint-disable stylelint-plugins/no-font-properties */
@import '../core/helpers';

@mixin spx-menu($theme, $status) {
  .spx-menu {
    padding: 0;

    mat-divider {
      background-color: color($theme, 'app-color', 'menu') !important;
      border-top-width: 2px;
      margin-bottom: 8px;
      opacity: 0.6;
    }
  }

  .mat-mdc-nav-list.spx-menu {
    padding: 0;
  }

  // menu item style
  .mat-mdc-nav-list.spx-menu .spx-menu-item {
    @include spx-menu-item($theme, false);

    height: 50px;
  }

  // child menu item style
  .mat-mdc-nav-list.spx-menu .spx-menu-item--child {
    height: 42px;
  }

  .mat-mdc-nav-list.spx-menu .spx-menu-item:hover:not(.active) {
    @include spx-menu-item($theme, true, 0.4);
    border-radius: 6px;
    border-color: transparent;
  }

  // Icons
  /* stylelint-disable-next-line selector-class-pattern */
  .mat-mdc-nav-list.spx-menu .spx-menu-item .spx-menu-item__expand {
    /* stylelint-disable-next-line selector-max-compound-selectors */
    mat-icon {
      font-size: 18px;
      height: 18px;
      line-height: 18px;
      width: 18px;
    }
  }

  /* stylelint-disable-next-line selector-max-compound-selectors */
  .mat-mdc-nav-list.spx-menu .spx-menu-item spx-icon {
    font-size: 22px;
    height: 22px;
    line-height: 22px;
    padding: 0;
    width: 22px;

    /* stylelint-disable-next-line selector-max-compound-selectors */
    mat-icon {
      font-size: 22px;
      height: 22px;
      line-height: 22px;
      width: 22px;
    }
  }

  // active menu item style
  .mat-mdc-nav-list.spx-menu .child-items.active {
    @include spx-menu-item($theme, true, 0.4);
  }

  // active child menu items container style
  .mat-mdc-nav-list.spx-menu .spx-menu-item.active {
    @include spx-menu-item($theme, true, 0.4);
  }

  .mat-mdc-nav-list.spx-menu .spx-menu-item.active:not(.spx-menu-item--expanded) {
    border-left: 6px solid contrast-color($theme, 'app-color', 'default');
    border-radius: 6px;
  }

  // active menu item style, when it's not a parent of other menu items
  .mat-mdc-nav-list.spx-menu .spx-menu-item:not(.spx-menu-item--parent).active {
    border-left-color: contrast-color($theme, 'app-color', 'default');
  }

  // active child menu item style
  .mat-mdc-nav-list.spx-menu .spx-menu-item--child.active {
    @include spx-menu-item($theme, true, 0.8);

    border-left-color: contrast-color($theme, 'app-color', 'default');
  }

  // Margins
  .mat-mdc-nav-list.spx-menu spx-menu-item {
    margin: 0 8px;
  }

  .mat-mdc-nav-list.spx-menu spx-menu-item:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .mat-mdc-nav-list.spx-menu .child-items spx-menu-item {
    margin: 0;
  }

  .mat-mdc-nav-list.spx-menu .child-items spx-menu-item:not(:last-of-type) {
    margin-bottom: 4px;
  }
}

@mixin spx-menu-item($theme, $active, $opacity: 0.4) {
  @if $active {
    opacity: 1;

    /* stylelint-disable-next-line selector-class-pattern */
    .spx-menu-item__content {
      color: contrast-color($theme, 'app-color') !important;
    }

    &::before {
      background-color: color($theme, 'app-color', 'menu');
      content: '';
      height: 100%;
      left: 0;
      opacity: 0.4;
      position: absolute;
      top: 0;
      width: 100%;
    }
  } @else {
    background-color: transparent;
    opacity: 0.7;

    /* stylelint-disable-next-line selector-class-pattern */
    .spx-menu-item__content {
      color: contrast-color($theme, 'app-color') !important;
    }
  }
}
/* stylelint-enable stylelint-plugins/no-font-properties */
