/* stylelint-disable selector-class-pattern */
@import '../core/helpers';

@mixin spx-sidenav($theme, $status) {
  .sidenav-container {
    mat-sidenav {
      background-color: color($theme, 'app-color');
      color: contrast-color($theme, 'app-color');
    }

    spx-icon-button {
      color: contrast-color($theme, 'app-color');
    }

    mat-sidenav-content {
      overflow: hidden;
    }

    mat-sidenav-content .sidenav__content {
      height: 100%;
      overflow-y: auto;
      background-color:var(--primary-10);
    }

    mat-sidenav-content *[content] {
      height: 100%;
    }

    mat-sidenav mat-toolbar {
      background-color: transparent;
    }

    mat-toolbar {
      height: 64px;
    }

    .mat-drawer-inner-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .sidenav__branding mat-toolbar {
      color: contrast-color($theme, 'app-color');
    }

    .sidenav__items {
      height: 100%;
      overflow-y: auto;
    }

    .sidenav__items::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .sidenav__items::-webkit-scrollbar-thumb {
      background-color: color($theme, 'app-color', 'menu');
    }

    .sidenav__divider {
      background-color: color($theme, 'app-color', 'menu');
      border-top-width: 2px;
    }

    /* stylelint-disable-next-line selector-no-qualifying-type */
    spx-menu[footer] {
      margin-bottom: 8px;
      margin-top: 8px;
    }
  }
}
/* stylelint-enable selector-class-pattern */
