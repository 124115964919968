@import '../core/helpers';

@mixin spx-icon($theme, $status) {
  spx-icon {
    display: flex;
    justify-content: center;
  }

  .spx-icon {
    &-primary:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
      @include spx-icon-color(color($theme));
    }

    &-accent:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
      @include spx-icon-color(color($theme, 'accent'));
    }

    &-warn:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
      @include spx-icon-color(color($theme, 'warn'));
    }

    @each $statusKey in $status {
      &-status-#{$statusKey}:not(.spx-button .spx-icon):not(.spx-icon-button .spx-icon) {
        @include spx-icon-color(color($theme, 'status', $statusKey));
      }
    }
  }
}

@mixin spx-icon-color($color) {
  color: $color;

  svg {
    fill: currentColor;
    stroke: currentColor;
  }
}
