/* stylelint-disable function-parentheses-space-inside */
@use '@angular/material' as mat;
@use 'sass:map';

@import '../components/_typography';

$typography-config: map.merge(
  mat.m2-define-typography-config(
    $headline-1: map.get($typography, 'headline-1'),
    $headline-2: map.get($typography, 'headline-2'),
    $headline-3: map.get($typography, 'headline-3'),
    $headline-4: map.get($typography, 'headline-4'),
    $headline-5: map.get($typography, 'h1'),
    $headline-6: map.get($typography, 'h2'),
    $subtitle-1: map.get($typography, 'h4'),
    $subtitle-2: map.get($typography, 'h3'),
    $body-1: map.get($typography, 'body1'),
    $body-2: map.get($typography, 'body2'),
    $caption: map.get($typography, 'label'),
    $button: map.get($typography, 'button'),
  ),
  ($typography)
);
/* stylelint-enable function-parentheses-space-inside */
