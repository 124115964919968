@import '../core/helpers';

@mixin spx-notification($theme, $status) {
  /* stylelint-disable selector-class-pattern */
  .mdc-snackbar__surface,
  .mdc-snackbar__label {
    padding: 0 !important;
  }
  /* stylelint-enable selector-class-pattern */

  .spx-notification {
    &.primary {
      background-color: color($theme);
      color: contrast-color($theme);
    }

    &.accent {
      background-color: color($theme, 'accent');
      color: contrast-color($theme, 'accent');
    }

    &.warn {
      background-color: color($theme, 'warn');
      color: contrast-color($theme, 'warn');
    }

    @each $statusKey in $status {
      &.status-#{$statusKey} {
        background-color: color($theme, 'status', $statusKey);
        color: color($theme, 'status', #{$statusKey}-contrast);
      }
    }
  }
}
