@use 'sass:map';
@use '@angular/material' as mat;

@import '../core/helpers';
@import '../core/global-styles';
@import '../core/icons';
@import '../_theming';

@import '_primary';
@import '_accent';
@import '_warn';
@import '_typography-config';

@include mat.core();

/* Theme */
$theme-primary: add-vars-to-palette(mat.m2-define-palette($primary-palette), 'primary');
$theme-accent: add-vars-to-palette(mat.m2-define-palette($accent-palette), 'accent');
$theme-warn: add-vars-to-palette(mat.m2-define-palette($warn-palette), 'warn');

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $typography-config,
  )
);

@include mat.all-component-themes($theme);

$interaction-typo: map.merge(
  $typography-config,
  (
    body-1: mat.m2-define-typography-level(14px, 14px, 400, 'Roboto, sans-serif', 0),
  )
);

@include mat.form-field-typography($interaction-typo);
@include mat.select-typography($interaction-typo);
@include mat.option-typography($interaction-typo);

$color-theme: add-dynamic-spx-theme-config(map.get($theme, 'color'));

@include spx-theme($color-theme, $typography-config);
